import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { CLIUser, Project, TableProject } from "./types";

export type Reducer = {
  organizationProjects: TableProject[] | null;
  areOrganizationProjectsLoading: boolean;
  project: Project | null;
  areProjectsLoading: boolean;
  isProjectLoading: boolean;
  isProjectCreating: boolean;
  isProjectUpdating: boolean;
  isProjectBudgetLimitUpdating: boolean;
  isProjectDeleting: boolean;
  cliUsers: CLIUser[] | null;
  cliUser: CLIUser | null;
  isCLIUserLoading: boolean;
  areCLIUsersLoading: boolean;
  isCLIUserCreating: boolean;
  isCLIUserUpdating: boolean;
  isCLIUserDeleting: boolean;
  isGroupToProjectAssigning: boolean;
  isGroupFromProjectRemoving: boolean;
};

const initialState: Reducer = {
  organizationProjects: null,
  areOrganizationProjectsLoading: false,
  project: null,
  areProjectsLoading: false,
  isProjectLoading: false,
  isProjectCreating: false,
  isProjectUpdating: false,
  isProjectBudgetLimitUpdating: false,
  isProjectDeleting: false,
  cliUsers: null,
  cliUser: null,
  isCLIUserLoading: false,
  areCLIUsersLoading: false,
  isCLIUserCreating: false,
  isCLIUserUpdating: false,
  isCLIUserDeleting: false,
  isGroupToProjectAssigning: false,
  isGroupFromProjectRemoving: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getProject.started,
    (state): Reducer => ({
      ...state,
      isProjectLoading: true
    })
  )
  .case(
    actions.getProject.done,
    (state, payload): Reducer => ({
      ...state,
      project: payload.result,
      isProjectLoading: false
    })
  )
  .case(
    actions.getProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectLoading: false
    })
  )
  .case(
    actions.getOrganizationProjects.started,
    (state): Reducer => ({
      ...state,
      areOrganizationProjectsLoading: true
    })
  )
  .case(
    actions.getOrganizationProjects.done,
    (state, payload): Reducer => ({
      ...state,
      organizationProjects: payload.result,
      areOrganizationProjectsLoading: false
    })
  )
  .case(
    actions.getOrganizationProjects.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationProjectsLoading: false
    })
  )
  .case(
    actions.createProject.started,
    (state): Reducer => ({
      ...state,
      isProjectCreating: true
    })
  )
  .case(
    actions.createProject.done,
    (state): Reducer => ({
      ...state,
      isProjectCreating: false
    })
  )
  .case(
    actions.createProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectCreating: false
    })
  )
  .case(
    actions.updateProject.started,
    (state): Reducer => ({
      ...state,
      isProjectUpdating: true
    })
  )
  .case(
    actions.updateProject.done,
    (state): Reducer => ({
      ...state,
      isProjectUpdating: false
    })
  )
  .case(
    actions.updateProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectUpdating: false
    })
  )

  .case(
    actions.updateProjectBudgetLimit.started,
    (state): Reducer => ({
      ...state,
      isProjectBudgetLimitUpdating: true
    })
  )
  .case(
    actions.updateProjectBudgetLimit.done,
    (state): Reducer => ({
      ...state,
      isProjectBudgetLimitUpdating: false
    })
  )
  .case(
    actions.updateProjectBudgetLimit.failed,
    (state): Reducer => ({
      ...state,
      isProjectBudgetLimitUpdating: false
    })
  )

  .case(
    actions.deleteProject.started,
    (state): Reducer => ({
      ...state,
      isProjectDeleting: true
    })
  )
  .case(
    actions.deleteProject.done,
    (state): Reducer => ({
      ...state,
      isProjectDeleting: false,
      project: null
    })
  )
  .case(
    actions.deleteProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectDeleting: false
    })
  )
  .case(
    actions.getCLIUser.started,
    (state): Reducer => ({
      ...state,
      isCLIUserLoading: true
    })
  )
  .case(
    actions.getCLIUser.done,
    (state, payload): Reducer => ({
      ...state,
      cliUser: payload.result,
      isCLIUserLoading: false
    })
  )
  .case(
    actions.getCLIUser.failed,
    (state): Reducer => ({
      ...state,
      isCLIUserLoading: false
    })
  )
  .case(
    actions.getCLIUsers.started,
    (state): Reducer => ({
      ...state,
      areCLIUsersLoading: true
    })
  )
  .case(
    actions.getCLIUsers.done,
    (state, payload): Reducer => ({
      ...state,
      cliUsers: payload.result,
      areCLIUsersLoading: false
    })
  )
  .case(
    actions.getCLIUsers.failed,
    (state): Reducer => ({
      ...state,
      areCLIUsersLoading: false
    })
  )
  .case(
    actions.createCLIUser.started,
    (state): Reducer => ({
      ...state,
      isCLIUserCreating: true
    })
  )
  .case(
    actions.createCLIUser.done,
    (state): Reducer => ({
      ...state,
      isCLIUserCreating: false
    })
  )
  .case(
    actions.createCLIUser.failed,
    (state): Reducer => ({
      ...state,
      isCLIUserCreating: false
    })
  )
  .case(
    actions.updateCLIUser.started,
    (state): Reducer => ({
      ...state,
      isCLIUserUpdating: true
    })
  )
  .case(
    actions.updateCLIUser.done,
    (state): Reducer => ({
      ...state,
      isCLIUserUpdating: false
    })
  )
  .case(
    actions.updateCLIUser.failed,
    (state): Reducer => ({
      ...state,
      isCLIUserUpdating: false
    })
  )
  .case(
    actions.deleteCLIUser.started,
    (state): Reducer => ({
      ...state,
      isCLIUserDeleting: true
    })
  )
  .case(
    actions.deleteCLIUser.done,
    (state): Reducer => ({
      ...state,
      isCLIUserDeleting: false
    })
  )
  .case(
    actions.deleteCLIUser.failed,
    (state): Reducer => ({
      ...state,
      isCLIUserDeleting: false
    })
  )
  .case(
    actions.clearCLIUsers,
    (state): Reducer => ({
      ...state,
      cliUsers: null
    })
  )
  .case(
    actions.assignGroupToProject.started,
    (state): Reducer => ({
      ...state,
      isGroupToProjectAssigning: true
    })
  )
  .case(
    actions.assignGroupToProject.done,
    (state): Reducer => ({
      ...state,
      isGroupToProjectAssigning: false
    })
  )
  .case(
    actions.assignGroupToProject.failed,
    (state): Reducer => ({
      ...state,
      isGroupToProjectAssigning: false
    })
  )
  .case(
    actions.removeGroupFromProject.started,
    (state): Reducer => ({
      ...state,
      isGroupFromProjectRemoving: true
    })
  )
  .case(
    actions.removeGroupFromProject.done,
    (state): Reducer => ({
      ...state,
      isGroupFromProjectRemoving: false
    })
  )
  .case(
    actions.removeGroupFromProject.failed,
    (state): Reducer => ({
      ...state,
      isGroupFromProjectRemoving: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
