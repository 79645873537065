import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import Link, { LinkProps } from "@mui/material/Link";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const DetailsTitle = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.9rem;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: 0.9rem;
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const DetailsInfoGray = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SummaryKeyValue = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const Tag = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;

export const LogToolbarContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 0)};
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const LengthTextField = styled(TextField)<TextFieldProps>`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const LogButtonsContainer = styled.div`
  display: flex;
`;

export const GetLogButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const LogContainer = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

export const LogText = styled.pre`
  white-space: pre-wrap;
  overflow-wrap: break-word; /* Replaces the deprecated 'word-break: break-word' */
  /* word-break: break-word; */
`;

export const NoDataText = styled(Typography)`
  text-align: center;
  line-height: ${({ theme }) => theme.spacing(7)};
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(20)};
`;

export const TableLink = styled(Link)<LinkProps & RouterLinkProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
`;

export const InfoContainer = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const SectionTitle = styled(Typography)`
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const InfoLabel = styled.span`
  font-weight: 500;
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.secondary
      : theme.palette.text.primary};
  font-size: 0.875rem;
`;

export const InfoValue = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  font-size: 0.875rem;
  word-break: break-all;
`;
