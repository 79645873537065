export enum DIALOG_TYPES {
  EDIT_LISTENER,
  EDIT_LB,
  EDIT_POOL,
  EDIT_HEALTH_MONITOR,
  CREATE_HEALTH_MONITOR,
  DELETE_HEALTH_MONITOR,
  DELETE_LISTENER,
  CREATE_L7POLICY,
  EDIT_L7POLICY,
  DELETE_L7POLICY,
  ASSIGN_POOL,
  UNASSIGN_POOL,
  DELETE_POOL,
  ADD_POOL_MEMBER,
  EDIT_POOL_MEMBER,
  REMOVE_POOL_MEMBER
}

export enum DELETE_DIALOG_TYPE {
  UNASSIGN_POOL = "unassign",
  DELETE_POOL = "delete"
}

export enum TABS {
  L7POLICIES,
  POOLS
}
