import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  CreateHealthMonitorParams,
  CreateHealthMonitorResponse,
  CreateL7PolicyParams,
  CreateL7PolicyResponse,
  CreateL7RuleParams,
  CreateL7RuleResponse,
  CreateListenerParams,
  CreateListenerResponse,
  CreateLoadBalancerParams,
  CreateLoadBalancerResponse,
  AddPoolMemberParams,
  AddPoolMemberResponse,
  CreatePoolParams,
  CreatePoolResponse,
  DeleteHealthMonitorParams,
  DeleteL7PolicyParams,
  DeleteL7RuleParams,
  DeleteListenerParams,
  DeleteLoadBalancerParams,
  RemovePoolMemberParams,
  DeletePoolParams,
  GetHealthMonitorDetailsParams,
  GetHealthMonitorDetailsResponse,
  GetHealthMonitorsListParams,
  GetHealthMonitorsListResponse,
  GetL7PoliciesListParams,
  GetL7PoliciesListResponse,
  GetL7PolicyDetailsParams,
  GetL7PolicyDetailsResponse,
  GetL7RuleDetailsParams,
  GetL7RuleDetailsResponse,
  GetL7RulesListParams,
  GetL7RulesListResponse,
  GetListenerDetailsParams,
  GetListenerDetailsResponse,
  GetListenersListParams,
  GetListenersListResponse,
  GetLoadBalancerDetailsParams,
  GetLoadBalancerDetailsResponse,
  GetLoadBalancersListParams,
  GetLoadBalancersListResponse,
  GetPoolDetailsParams,
  GetPoolDetailsResponse,
  GetPoolMemberDetailsParams,
  GetPoolMemberDetailsResponse,
  GetPoolMembersListParams,
  GetPoolMembersListResponse,
  GetPoolsListParams,
  GetPoolsListResponse,
  UpdateHealthMonitorParams,
  UpdateHealthMonitorResponse,
  UpdateL7PolicyParams,
  UpdateL7PolicyResponse,
  UpdateL7RuleParams,
  UpdateL7RuleResponse,
  UpdateListenerParams,
  UpdateListenerResponse,
  UpdateLoadBalancerParams,
  UpdateLoadBalancerResponse,
  UpdatePoolMemberParams,
  UpdatePoolMemberResponse,
  UpdatePoolParams,
  UpdatePoolResponse
} from "./types";
import { Policy } from "@mui/icons-material";

// LOAD_BALANCERS _________________________________________________________________________________

export function* listLoadBalancersSaga(
  action: Action<GetLoadBalancersListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetLoadBalancersListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listLoadBalancers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.listLoadBalancers.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Load Balancers data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getLoadBalancerSaga(
  action: Action<GetLoadBalancerDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, lbId } = action.payload;
    const response: AxiosResponse<GetLoadBalancerDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getLoadBalancer.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getLoadBalancer.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Load Balancer details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createLoadBalancerSaga(
  action: Action<CreateLoadBalancerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the Load Balancer...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateLoadBalancerResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers`,
      data
    );

    yield put(
      actions.createLoadBalancer.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Load Balancer has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createLoadBalancer.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create Load Balancer ",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateLoadBalancerSaga(
  action: Action<UpdateLoadBalancerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the Load Balancer...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, data } = action.payload;
    const response: AxiosResponse<UpdateLoadBalancerResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}`,
      data
    );
    yield put(
      actions.updateLoadBalancer.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Load Balancer has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateLoadBalancer.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update Load Balancer",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteLoadBalancerSaga(
  action: Action<DeleteLoadBalancerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the Load Balancer...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}`
    );
    yield put(
      actions.deleteLoadBalancer.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Load Balancer has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteLoadBalancer.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete Load Balancer",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// LISTENERS _________________________________________________________________________________

export function* listListenersSaga(
  action: Action<GetListenersListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, lbId } = action.payload;
    const response: AxiosResponse<GetListenersListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/listeners`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listListeners.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.listListeners.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Listeners data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getListenerSaga(
  action: Action<GetListenerDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, lbId, listenerId } = action.payload;
    const response: AxiosResponse<GetListenerDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/listeners/${listenerId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getListener.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getListener.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Listener details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createListenerSaga(
  action: Action<CreateListenerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the Listener...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, data } = action.payload;
    const response: AxiosResponse<CreateListenerResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/listeners`,
      data
    );

    yield put(
      actions.createListener.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Listener has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createListener.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create Listener ",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateListenerSaga(
  action: Action<UpdateListenerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the Listener...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, listenerId, data } = action.payload;
    const response: AxiosResponse<UpdateListenerResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/listeners/${listenerId}`,
      data
    );
    yield put(
      actions.updateListener.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Listener has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateListener.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update Listener",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteListenerSaga(
  action: Action<DeleteListenerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the Listener...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, listenerId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/listeners/${listenerId}`
    );
    yield put(
      actions.deleteListener.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Listener has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteListener.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete Listener",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// POOLS _________________________________________________________________________________

export function* listPoolsSaga(
  action: Action<GetPoolsListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, lbId } = action.payload;
    const response: AxiosResponse<GetPoolsListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/pools`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listPools.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.listPools.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Pools data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getPoolSaga(
  action: Action<GetPoolDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, lbId, poolId } = action.payload;
    const response: AxiosResponse<GetPoolDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/pools/${poolId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getPool.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getPool.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Pool details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createPoolSaga(
  action: Action<CreatePoolParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the Pool...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, data } = action.payload;
    const response: AxiosResponse<CreatePoolResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/pools`,
      data
    );

    yield put(
      actions.createPool.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Pool has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.createPool.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create Pool ",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updatePoolSaga(
  action: Action<UpdatePoolParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the Pool ...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, poolId, data } = action.payload;
    const response: AxiosResponse<UpdatePoolResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/pools/${poolId}`,
      data
    );
    yield put(
      actions.updatePool.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Pool has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.updatePool.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update Pool",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deletePoolSaga(
  action: Action<DeletePoolParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the Pool...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, lbId, poolId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/loadbalancers/${lbId}/pools/${poolId}`
    );
    yield put(
      actions.deletePool.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Pool has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.deletePool.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete Pool",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// POOL MEMBERS _________________________________________________________________________________

export function* listPoolMembersSaga(
  action: Action<GetPoolMembersListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, poolId } = action.payload;
    const response: AxiosResponse<GetPoolMembersListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/members`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listPoolMembers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.listPoolMembers.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Pool Members data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getPoolMemberSaga(
  action: Action<GetPoolMemberDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, poolId, memberId } = action.payload;
    const response: AxiosResponse<GetPoolMemberDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/members/${memberId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getPoolMember.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getPoolMember.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Pool Member details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addPoolMemberSaga(
  action: Action<AddPoolMemberParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Adding the Pool Member...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, poolId, data } = action.payload;
    const response: AxiosResponse<AddPoolMemberResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/members`,
      data
    );

    yield put(
      actions.addPoolMember.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Pool Member has been successfully added.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.addPoolMember.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add Pool Member ",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updatePoolMemberSaga(
  action: Action<UpdatePoolMemberParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the Pool Member ...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, poolId, memberId, data } = action.payload;
    const response: AxiosResponse<UpdatePoolMemberResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/members/${memberId}`,
      data
    );
    yield put(
      actions.updatePoolMember.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Pool Member has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updatePoolMember.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update Pool Member",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removePoolMemberSaga(
  action: Action<RemovePoolMemberParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Removing the Pool Member...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, poolId, memberId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/members/${memberId}`
    );
    yield put(
      actions.removePoolMember.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Pool Member has been successfully removed.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removePoolMember.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove Pool Member",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// L7 POLICIES _________________________________________________________________________________

export function* listL7PoliciesSaga(
  action: Action<GetL7PoliciesListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, listenerId } = action.payload;
    const response: AxiosResponse<GetL7PoliciesListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/listeners/${listenerId}/l7policies`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listL7Policies.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.listL7Policies.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get L7 Policies data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getL7PolicySaga(
  action: Action<GetL7PolicyDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, listenerId, l7PolicyId } = action.payload;
    const response: AxiosResponse<GetL7PolicyDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/listeners/${listenerId}/l7policies/${l7PolicyId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getL7Policy.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getL7Policy.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get L7 Policy details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createL7PolicySaga(
  action: Action<CreateL7PolicyParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the L7 Policy...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, listenerId, data } = action.payload;
    const response: AxiosResponse<CreateL7PolicyResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/listeners/${listenerId}/l7policies/`,
      data
    );

    yield put(
      actions.createL7Policy.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "L7 Policy has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createL7Policy.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create L7 Policy ",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateL7PolicySaga(
  action: Action<UpdateL7PolicyParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the L7 Policy ...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, listenerId, l7PolicyId, data } =
      action.payload;
    const response: AxiosResponse<UpdateL7PolicyResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/listeners/${listenerId}/l7policies/${l7PolicyId}`,
      data
    );
    yield put(
      actions.updateL7Policy.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "L7 Policy has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateL7Policy.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update L7 Policy",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteL7PolicySaga(
  action: Action<DeleteL7PolicyParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the L7 Policy...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, listenerId, l7PolicyId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/listeners/${listenerId}/l7policies/${l7PolicyId}`
    );
    yield put(
      actions.deleteL7Policy.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "L7 Policy has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteL7Policy.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete L7 Policy",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// L7 POLICY RULES _________________________________________________________________________________

export function* listL7PolicyRulesSaga(
  action: Action<GetL7RulesListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, l7PolicyId } = action.payload;
    const response: AxiosResponse<GetL7RulesListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/l7policies/${l7PolicyId}/rules`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listL7PolicyRules.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.listL7PolicyRules.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get L7 Policy Rules data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getL7PolicyRuleSaga(
  action: Action<GetL7RuleDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, l7PolicyId, ruleId } = action.payload;
    const response: AxiosResponse<GetL7RuleDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/l7policies/${l7PolicyId}/rules/${ruleId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getL7PolicyRule.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getL7PolicyRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get L7 Policy Rule details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createL7PolicyRuleSaga(
  action: Action<CreateL7RuleParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the L7 Policy Rule...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, l7PolicyId, data } = action.payload;
    const response: AxiosResponse<CreateL7RuleResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/l7policies/${l7PolicyId}/rules`,
      data
    );

    yield put(
      actions.createL7PolicyRule.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "L7 Policy Rule has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createL7PolicyRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create L7 Policy Rule",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateL7PolicyRuleSaga(
  action: Action<UpdateL7RuleParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the L7 Policy Rule...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, l7PolicyId, ruleId, data } = action.payload;
    const response: AxiosResponse<UpdateL7RuleResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/l7policies/${l7PolicyId}/rules/${ruleId}`,
      data
    );
    yield put(
      actions.updateL7PolicyRule.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "L7 Policy Rule has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateL7PolicyRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update L7 Policy Rule",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteL7PolicyRuleSaga(
  action: Action<DeleteL7RuleParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the L7 Policy Rule...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, l7PolicyId, ruleId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/l7policies/${l7PolicyId}/rules/${ruleId}`
    );
    yield put(
      actions.deleteL7PolicyRule.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "L7 Policy Rule has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteL7PolicyRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete L7 Policy Rule",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// HEALTH MONITORS _________________________________________________________________________________

export function* listHealthMonitorsSaga(
  action: Action<GetHealthMonitorsListParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, poolId } = action.payload;
    const response: AxiosResponse<GetHealthMonitorsListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/monitors`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.listHealthMonitors.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.listHealthMonitors.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Health Monitors data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getHealthMonitorSaga(
  action: Action<GetHealthMonitorDetailsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, poolId, monitorId } = action.payload;
    const response: AxiosResponse<GetHealthMonitorDetailsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/monitors/${monitorId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getHealthMonitor.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getHealthMonitor.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Health Monitor details data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createHealthMonitorSaga(
  action: Action<CreateHealthMonitorParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the Health Monitor...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, poolId, data } = action.payload;
    const response: AxiosResponse<CreateHealthMonitorResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/monitors`,
      data
    );

    yield put(
      actions.createHealthMonitor.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Health Monitor has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createHealthMonitor.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create Health Monitor",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateHealthMonitorSaga(
  action: Action<UpdateHealthMonitorParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the Health Monitor...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, poolId, monitorId, data } = action.payload;
    const response: AxiosResponse<UpdateHealthMonitorResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/monitors/${monitorId}`,
      data
    );
    yield put(
      actions.updateHealthMonitor.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Health Monitor has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateHealthMonitor.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update Health Monitor",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteHealthMonitorSaga(
  action: Action<DeleteHealthMonitorParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the Health Monitor...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, poolId, monitorId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-load-balancer/method/${projectId}/pools/${poolId}/monitors/${monitorId}`
    );
    yield put(
      actions.deleteHealthMonitor.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Health Monitor has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteHealthMonitor.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete Health Monitor",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.listLoadBalancers.started, listLoadBalancersSaga),
    takeLeading(actions.getLoadBalancer.started, getLoadBalancerSaga),
    takeEvery(actions.createLoadBalancer.started, createLoadBalancerSaga),
    takeEvery(actions.updateLoadBalancer.started, updateLoadBalancerSaga),
    takeEvery(actions.deleteLoadBalancer.started, deleteLoadBalancerSaga),

    takeLeading(actions.listListeners.started, listListenersSaga),
    takeLeading(actions.getListener.started, getListenerSaga),
    takeEvery(actions.createListener.started, createListenerSaga),
    takeEvery(actions.updateListener.started, updateListenerSaga),
    takeEvery(actions.deleteListener.started, deleteListenerSaga),

    takeLeading(actions.listPools.started, listPoolsSaga),
    takeLeading(actions.getPool.started, getPoolSaga),
    takeEvery(actions.createPool.started, createPoolSaga),
    takeEvery(actions.updatePool.started, updatePoolSaga),
    takeEvery(actions.deletePool.started, deletePoolSaga),

    takeLeading(actions.listPoolMembers.started, listPoolMembersSaga),
    takeLeading(actions.getPoolMember.started, getPoolMemberSaga),
    takeEvery(actions.addPoolMember.started, addPoolMemberSaga),
    takeEvery(actions.updatePoolMember.started, updatePoolMemberSaga),
    takeEvery(actions.removePoolMember.started, removePoolMemberSaga),

    takeLeading(actions.listL7Policies.started, listL7PoliciesSaga),
    takeLeading(actions.getL7Policy.started, getL7PolicySaga),
    takeEvery(actions.createL7Policy.started, createL7PolicySaga),
    takeEvery(actions.updateL7Policy.started, updateL7PolicySaga),
    takeEvery(actions.deleteL7Policy.started, deleteL7PolicySaga),

    takeLeading(actions.listL7PolicyRules.started, listL7PolicyRulesSaga),
    takeLeading(actions.getL7PolicyRule.started, getL7PolicyRuleSaga),
    takeEvery(actions.createL7PolicyRule.started, createL7PolicyRuleSaga),
    takeEvery(actions.updateL7PolicyRule.started, updateL7PolicyRuleSaga),
    takeEvery(actions.deleteL7PolicyRule.started, deleteL7PolicyRuleSaga),

    takeLeading(actions.listHealthMonitors.started, listHealthMonitorsSaga),
    takeLeading(actions.getHealthMonitor.started, getHealthMonitorSaga),
    takeEvery(actions.createHealthMonitor.started, createHealthMonitorSaga),
    takeEvery(actions.updateHealthMonitor.started, updateHealthMonitorSaga),
    takeEvery(actions.deleteHealthMonitor.started, deleteHealthMonitorSaga)
  ]);
}
