export enum DIALOG_TYPES {
  EDIT_LISTENER,
  EDIT_LB,
  EDIT_HEALTH_MONITOR,
  CREATE_HEALTH_MONITOR,
  DELETE_HEALTH_MONITOR,
  EDIT_POOL,
  DELETE_POOL,
  ADD_POOL_MEMBER,
  EDIT_POOL_MEMBER,
  REMOVE_POOL_MEMBER
}

export enum EDIT_DIALOG_TYPE {
  LISTENER = "listener",
  POOL = "pool",
  MONITOR = "monitor",
  LB = "lb"
}

export enum TABS {
  L7POLICIES,
  POOLS
}
