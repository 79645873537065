import actionCreatorFactory from "typescript-fsa";
import {
  AssignGroupToProjectParams,
  CreateCLIUserParams,
  CreateCLIUserResponse,
  CreateProjectParams,
  CreateProjectResponse,
  DeleteCLIUserParams,
  DeleteCLIUserResponse,
  DeleteProjectParams,
  GetCLIUserParams,
  GetCLIUserResponse,
  GetCLIUsersParams,
  GetCLIUsersResponse,
  GetOrganizationProjectsParams,
  GetOrganizationProjectsResponse,
  GetProjectParams,
  GetProjectResponse,
  RemoveGroupFromProjectParams,
  UpdateCLIUserParams,
  UpdateCLIUserResponse,
  UpdateProjectBudgetLimitParams,
  UpdateProjectBudgetLimitResponse,
  UpdateProjectParams,
  UpdateProjectResponse
} from "./types";

const actionCreator = actionCreatorFactory("PROJECTS");

export const getProject = actionCreator.async<
  GetProjectParams,
  GetProjectResponse,
  unknown
>("GET_PROJECT");

export const getOrganizationProjects = actionCreator.async<
  GetOrganizationProjectsParams,
  GetOrganizationProjectsResponse,
  unknown
>("GET_ORGANIZATION_PROJECTS");

export const createProject = actionCreator.async<
  CreateProjectParams,
  CreateProjectResponse,
  unknown
>("CREATE_PROJECT");

export const updateProject = actionCreator.async<
  UpdateProjectParams,
  UpdateProjectResponse,
  unknown
>("UPDATE_PROJECT");

export const updateProjectBudgetLimit = actionCreator.async<
  UpdateProjectBudgetLimitParams,
  UpdateProjectBudgetLimitResponse,
  unknown
>("UPDATE_PROJECT_BUDGET_LIMIT");

export const deleteProject = actionCreator.async<
  DeleteProjectParams,
  void,
  unknown
>("DELETE_PROJECT");

export const clear = actionCreator<void>("CLEAR");

export const getCLIUser = actionCreator.async<
  GetCLIUserParams,
  GetCLIUserResponse,
  unknown
>("GET_CLI_USER");

export const getCLIUsers = actionCreator.async<
  GetCLIUsersParams,
  GetCLIUsersResponse,
  unknown
>("GET_CLI_USERS");

export const createCLIUser = actionCreator.async<
  CreateCLIUserParams,
  CreateCLIUserResponse,
  unknown
>("CREATE_CLI_USER");

export const updateCLIUser = actionCreator.async<
  UpdateCLIUserParams,
  UpdateCLIUserResponse,
  unknown
>("UPDATE_CLI_USER");

export const deleteCLIUser = actionCreator.async<
  DeleteCLIUserParams,
  DeleteCLIUserResponse,
  unknown
>("DELETE_CLI_USER");

export const clearCLIUsers = actionCreator<void>("CLEAR_CLI_USERS");

export const assignGroupToProject = actionCreator.async<
  AssignGroupToProjectParams,
  void,
  unknown
>("ASSIGN_GROUP_TO_PROJECT");

export const removeGroupFromProject = actionCreator.async<
  RemoveGroupFromProjectParams,
  void,
  unknown
>("REMOVE_GROUP_TO_PROJECT");
