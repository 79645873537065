import Typography, { TypographyProps } from "@mui/material/Typography";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import styled from "styled-components";

export const Percentage = styled(Typography)<
  TypographyProps<"h4", { component: "h4" }> & { progress: number }
>`
  color: ${({ theme, progress }) =>
    progress === 100
      ? theme.palette.error.main
      : progress >= 80
        ? theme.palette.warning.main
        : theme.palette.primary.main};
  font-size: 24px;
  font-weight: 450;
  transition:
    color 0.3s ease,
    transform 0.3s ease;

  &:hover {
    transform: scaleY(1.1) scaleX(1.1);
  }
`;

export const StyledProgress = styled(LinearProgress)<{ progress: number }>(
  ({ theme, progress }) => ({
    // marginLeft: "20px",
    marginBottom: "5px",
    height: "5px",
    borderRadius: "30px",
    boxShadow:
      theme.palette.mode === "dark"
        ? `0px 1px 3px 0px ${theme.palette.grey["900"]}, 0px 1px 1px 0px ${theme.palette.grey["900"]}, 0px 2px 1px -1px ${theme.palette.secondary.main}`
        : theme.shadows[1],
    transition:
      "background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease",
    "&:hover": {
      transform: "scaleY(1.5) scaleX(1.015)",
      boxShadow:
        theme.palette.mode === "dark"
          ? `0px 1px 1px -2px ${theme.palette.grey["900"]}, 0px 1px 1px -1px ${theme.palette.secondary.main}, 0px 1px 5px 0px ${theme.palette.grey["900"]}`
          : theme.shadows[1]
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey["900"]
          : theme.palette.grey["100"]
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "30px",
      backgroundColor:
        progress === 100
          ? theme.palette.error.dark // red 100%
          : progress >= 80
            ? theme.palette.warning.dark // orange 80-99%
            : theme.palette.secondary.light
      // backgroundColor: theme.palette.secondary.main
    }
  })
);

export const MetricTitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  text-align: center;
  /* align-items: center; */
  white-space: nowrap;
`;

export const MetricTitle = styled(Typography)`
  /* margin-right: ${({ theme }) => theme.spacing(0.5)}; */
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 500;
  text-align: center;
`;

export const CaptionText = styled(Typography).attrs({
  variant: "caption"
})`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.common.white
      : theme.palette.text.secondary};
  margin: 0 10px 0 0;
`;
