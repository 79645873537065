import { isObject } from "typeGuards/isObject";
import { isString } from "typeGuards/isString";
import { isUndefined } from "typeGuards/isUndefined";

const getAppConfig = (): AppConfig => {
  if (NODE_ENV === "development") {
    try {
      const REQUIRED_VARIABLES = {
        __KEYCLOAK_URL: __KEYCLOAK_URL,
        __KEYCLOAK_REALM: __KEYCLOAK_REALM,
        __KEYCLOAK_CLIENT_ID: __KEYCLOAK_CLIENT_ID,
        __AVAILABLE_REGIONS: __AVAILABLE_REGIONS,
        __GPU_ENABLED_REGIONS: __GPU_ENABLED_REGIONS,
        __GROUP_REGIONS_MAP: __GROUP_REGIONS_MAP,
        __REGION_GROUP: __REGION_GROUP,
        __HUBSPOT_PORTAL_ID: __HUBSPOT_PORTAL_ID,
        __HUBSPOT_CONTACT_SUPPORT_FORM_ID: __HUBSPOT_CONTACT_SUPPORT_FORM_ID,
        __DEFAULT_ALLOWED_ORG_NUMBER: __DEFAULT_ALLOWED_ORG_NUMBER,
        __DEFAULT_ALLOWED_PROJECT_NUMBER: __DEFAULT_ALLOWED_PROJECT_NUMBER,
        __DOMAIN: __DOMAIN,
        __CONSOLE_DOMAIN: __CONSOLE_DOMAIN,
        __SUPPORT_MOD: __SUPPORT_MOD
      };

      Object.keys(REQUIRED_VARIABLES).forEach((x) => {
        if (!isString(REQUIRED_VARIABLES[x])) {
          throw Error(`The value of the ${x} variable should be a string`);
        }
      });

      const OPTIONAL_VARIABLES = {
        __THEME_TITLE: __THEME_TITLE,
        __THEME_IS_DARK_MODE_ENABLED: __THEME_IS_DARK_MODE_ENABLED,
        __THEME_FAVICON_URL: __THEME_FAVICON_URL,
        __THEME_LOGO_URL: __THEME_LOGO_URL,
        __THEME_DARK_MODE_LOGO_URL: __THEME_DARK_MODE_LOGO_URL,
        __THEME_LOGO_DESCRIPTION: __THEME_LOGO_DESCRIPTION,
        __THEME_LOGO_BACKGROUND_COLOR: __THEME_LOGO_BACKGROUND_COLOR,
        __THEME_PRIMARY_COLOR: __THEME_PRIMARY_COLOR,
        __THEME_SECONDARY_COLOR: __THEME_SECONDARY_COLOR,
        __THEME_BACKGROUND_COLOR: __THEME_BACKGROUND_COLOR,
        __THEME_TABLE_HEADERS_COLOR: __THEME_TABLE_HEADERS_COLOR,
        __THEME_FOOTER_TEXT: __THEME_FOOTER_TEXT,
        __SENTRY_DSN: __SENTRY_DSN,
        __SENTRY_ENVIRONMENT: __SENTRY_ENVIRONMENT,
        __NEWS_FEED_ENABLED: __NEWS_FEED_ENABLED,
        __FORM_DEV_TOOLS_ENABLED: __FORM_DEV_TOOLS_ENABLED,
        __DOCUMENTATION_URL: __DOCUMENTATION_URL,
        __TERMS_URL: __TERMS_URL,
        __PRIVACY_URL: __PRIVACY_URL,
        __COPYRIGHT_ENABLED: __COPYRIGHT_ENABLED,
        __SUPPORT_EMAIL: __SUPPORT_EMAIL,
        __SUPPORT_PHONES: __SUPPORT_PHONES,
        __DBaaS_ENABLED: __DBaaS_ENABLED,
        __BACKUPS_ENABLED: __BACKUPS_ENABLED,
        __LOAD_BALANCERS_ENABLED: __LOAD_BALANCERS_ENABLED,
        __HIDDEN_REGIONS: __HIDDEN_REGIONS
      };

      Object.keys(OPTIONAL_VARIABLES).forEach((x) => {
        if (
          !isString(OPTIONAL_VARIABLES[x]) &&
          !isUndefined(OPTIONAL_VARIABLES[x])
        ) {
          throw Error(
            `The value of the ${x} variable should be a string or undefined`
          );
        }
      });
    } catch (e) {
      throw Error(
        `Environment configuration is invalid: ${(e as Error).message}.`
      );
    }

    if (
      isString(__SENTRY_DSN) &&
      __SENTRY_DSN.length > 0 &&
      (!isString(__SENTRY_ENVIRONMENT) || __SENTRY_ENVIRONMENT.length === 0)
    ) {
      throw Error(
        "Environment configuration is invalid. Value for SENTRY_ENVIRONMENT variable is missing."
      );
    }

    // // parse __GROUP_REGIONS_MAP into Map<string, { group: string; isHidden: boolean }>
    // const jsonGroupRegionArray: string[] = JSON.parse(
    //   __GROUP_REGIONS_MAP as string
    // );
    // const map = new Map<string, { group: string; isHidden: boolean }>();
    // jsonGroupRegionArray.forEach((item) => {
    //   const key = Object.keys(item)[0];
    //   const value: { group: string; isHidden: boolean } = item[key];
    //   map.set(key, value);
    // });

    return {
      keycloak: {
        url: __KEYCLOAK_URL as string,
        realm: __KEYCLOAK_REALM as string,
        clientId: __KEYCLOAK_CLIENT_ID as string
      },
      hubSpot: {
        isChatWidgetEnabled: __HUBSPOT_IS_CHAT_WIDGET_ENABLED === "true",
        portalId: __HUBSPOT_PORTAL_ID as string,
        contactSupportFormId: __HUBSPOT_CONTACT_SUPPORT_FORM_ID as string
      },
      availableRegions: (__AVAILABLE_REGIONS as string).split(","),
      gpuEnabledRegions: (__GPU_ENABLED_REGIONS as string).split(";"),
      regionGroup: __REGION_GROUP as string,
      groupRegionMap: __GROUP_REGIONS_MAP as string,
      theme: {
        title: __THEME_TITLE as string | undefined,
        isDarkModeEnabled: __THEME_IS_DARK_MODE_ENABLED === "true",
        faviconURL: __THEME_FAVICON_URL as string | undefined,
        logoURL: __THEME_LOGO_URL as string | undefined,
        darkModeLogoURL: __THEME_DARK_MODE_LOGO_URL as string | undefined,
        logoDescription: __THEME_LOGO_DESCRIPTION as string | undefined,
        logoBackgroundColor: __THEME_LOGO_BACKGROUND_COLOR as
          | string
          | undefined,
        primaryColor: __THEME_PRIMARY_COLOR as string | undefined,
        secondaryColor: __THEME_SECONDARY_COLOR as string | undefined,
        backgroundColor: __THEME_BACKGROUND_COLOR as string | undefined,
        tableHeadersColor: __THEME_TABLE_HEADERS_COLOR as string | undefined,
        footerText: __THEME_FOOTER_TEXT as string | undefined
      },
      sentry: {
        dsn: __SENTRY_DSN as string | undefined,
        environment: __SENTRY_ENVIRONMENT as string | undefined
      },

      isDbaasEnabled: __DBaaS_ENABLED === "true",
      isBackupsEnabled: __BACKUPS_ENABLED === "true",
      isLoadBalancersEnabled: __LOAD_BALANCERS_ENABLED === "true",
      isNewsFeedEnabled: __NEWS_FEED_ENABLED === "true",
      defaultAllowedOrgNumber: Number(__DEFAULT_ALLOWED_ORG_NUMBER),
      defaultAllowedProjectNumber: Number(__DEFAULT_ALLOWED_PROJECT_NUMBER),
      areFormDevToolsEnabled: __FORM_DEV_TOOLS_ENABLED === "true",
      maintenanceMode: false,
      documentationUrl: __DOCUMENTATION_URL as string | undefined,
      termsUrl: __TERMS_URL as string | undefined,
      privacyUrl: __PRIVACY_URL as string | undefined,
      supportEmail: __SUPPORT_EMAIL as string | undefined,
      supportPhones: __SUPPORT_PHONES as string | undefined,
      supportMod: __SUPPORT_MOD as string,
      isCopyrightEnabled: __COPYRIGHT_ENABLED === "true",
      domain: __DOMAIN as string,
      consoleDomain: __CONSOLE_DOMAIN as string,
      hiddenRegions: (__HIDDEN_REGIONS as string).split(",") || []
    };
  }

  if (!isObject(envConfig)) {
    throw Error("Environment configuration is invalid.");
  }

  return envConfig;
};

export const appConfig = getAppConfig();
