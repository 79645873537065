export enum DIALOG_TYPES {
  CREATE_LOAD_BALANCER,
  EDIT_LOAD_BALANCER,
  DELETE_LOAD_BALANCER,
  ASSOCIATE_FLOATING_IP,
  DISASSOCIATE_FLOATING_IP
}

export enum TABS {
  LOAD_BALANCERS,
  LISTENERS,
  POOLS
}
