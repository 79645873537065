import { FC, useEffect, useState } from "react";
import * as s from "./styles";

import { LinerProgressWithLabelProps } from "./types";
import { roundTo } from "utils/roundTo";
import { Box, Popover, Tooltip, Typography, useTheme } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

const ANIMATION_FREQUENCY = 50;
const ANIMATION_PERCENTAGE_STEP = 5;
const ANIMATION_PERCENTAGE_STEP_SLOW = 3;

export const LinearProgressWithLabelBig: FC<LinerProgressWithLabelProps> = ({
  value,
  maxValue,
  unit,
  title,
  bigTitle,
  bottomCaption,
  titleWithCaption,
  tooltip
}: LinerProgressWithLabelProps) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [displayedPercentage, setDisplayedPercentage] = useState(0);
  const percentageValue = (value / maxValue) * 100;

  useEffect(() => {
    const timerId = setInterval(() => {
      setProgress((prevProgress) =>
        Math.min(
          100,
          prevProgress +
            Math.min(ANIMATION_PERCENTAGE_STEP, percentageValue - prevProgress)
        )
      );

      setDisplayedPercentage((prevPercentage) =>
        Math.min(
          100,
          prevPercentage +
            Math.min(
              ANIMATION_PERCENTAGE_STEP_SLOW,
              percentageValue - prevPercentage
            )
        )
      );
    }, ANIMATION_FREQUENCY);

    return () => {
      clearInterval(timerId);
    };
  }, [percentageValue]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: "100%", mt: 0 }}>
      {bigTitle && (
        <s.MetricTitleContainer>
          <s.MetricTitle>{bigTitle}</s.MetricTitle>
        </s.MetricTitleContainer>
      )}

      {titleWithCaption && (
        <Box mt={0} display="flex" justifyContent="center">
          <s.CaptionText>
            {`${title}: ${roundTo(Number(value), 2)} of ${roundTo(Number(maxValue), 2)} ${unit}`}
          </s.CaptionText>

          {tooltip && (
            <>
              <Box
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{ display: "inline-flex", cursor: "pointer" }}
              >
                <InfoOutlined fontSize="small" color="primary" />
              </Box>

              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                sx={(theme) => ({
                  "& .MuiPaper-root": {
                    padding: "8px 12px",
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                    borderRadius: "6px",
                    fontSize: "0.85rem",
                    maxWidth: "250px",
                    textAlign: "center"
                  }
                })}
              >
                <Typography variant="caption">{tooltip}</Typography>
              </Popover>
            </>
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <s.StyledProgress
            variant="determinate"
            value={progress}
            progress={progress}
          />
        </Box>
        <Box sx={{ minWidth: 50, ml: 2 }}>
          {/* <s.Percentage component="h4">{`${Math.round(percentageValue)}%`}</s.Percentage> */}
          <s.Percentage component="h4" progress={progress}>{`${Math.round(
            displayedPercentage
          )}%`}</s.Percentage>
        </Box>
      </Box>
      {bottomCaption && (
        <Box mt={0} display="flex" justifyContent="center">
          {" "}
          <s.CaptionText>
            {`used: ${roundTo(Number(value), 2)} of ${roundTo(maxValue, 2)} (free: ${roundTo(maxValue - value, 2)} ${unit})`}
          </s.CaptionText>
        </Box>
      )}
    </Box>
  );
};
