import actionCreatorFactory from "typescript-fsa";
import {
  CreateHealthMonitorParams,
  CreateHealthMonitorResponse,
  CreateL7PolicyParams,
  CreateL7PolicyResponse,
  CreateL7RuleParams,
  CreateL7RuleResponse,
  CreateListenerParams,
  CreateListenerResponse,
  CreateLoadBalancerParams,
  CreateLoadBalancerResponse,
  AddPoolMemberParams,
  AddPoolMemberResponse,
  CreatePoolParams,
  CreatePoolResponse,
  DeleteHealthMonitorParams,
  DeleteL7PolicyParams,
  DeleteL7RuleParams,
  DeleteListenerParams,
  DeleteLoadBalancerParams,
  RemovePoolMemberParams,
  DeletePoolParams,
  GetHealthMonitorDetailsParams,
  GetHealthMonitorDetailsResponse,
  GetHealthMonitorsListParams,
  GetHealthMonitorsListResponse,
  GetL7PoliciesListParams,
  GetL7PoliciesListResponse,
  GetL7PolicyDetailsParams,
  GetL7PolicyDetailsResponse,
  GetL7RuleDetailsParams,
  GetL7RuleDetailsResponse,
  GetL7RulesListParams,
  GetL7RulesListResponse,
  GetListenerDetailsParams,
  GetListenerDetailsResponse,
  GetListenersListParams,
  GetListenersListResponse,
  GetLoadBalancerDetailsParams,
  GetLoadBalancerDetailsResponse,
  GetLoadBalancersListParams,
  GetLoadBalancersListResponse,
  GetPoolDetailsParams,
  GetPoolDetailsResponse,
  GetPoolMemberDetailsParams,
  GetPoolMemberDetailsResponse,
  GetPoolMembersListParams,
  GetPoolMembersListResponse,
  GetPoolsListParams,
  GetPoolsListResponse,
  UpdateHealthMonitorParams,
  UpdateHealthMonitorResponse,
  UpdateL7PolicyParams,
  UpdateL7PolicyResponse,
  UpdateL7RuleParams,
  UpdateL7RuleResponse,
  UpdateListenerParams,
  UpdateListenerResponse,
  UpdateLoadBalancerParams,
  UpdateLoadBalancerResponse,
  UpdatePoolMemberParams,
  UpdatePoolMemberResponse,
  UpdatePoolParams,
  UpdatePoolResponse
} from "./types";

const actionCreator = actionCreatorFactory("LOAD_BALANCERS");

// LOAD_BALANCERS
export const listLoadBalancers = actionCreator.async<
  GetLoadBalancersListParams,
  GetLoadBalancersListResponse,
  unknown
>("LIST_LOAD_BALANCERS");

export const getLoadBalancer = actionCreator.async<
  GetLoadBalancerDetailsParams,
  GetLoadBalancerDetailsResponse,
  unknown
>("GET_LOAD_BALANCER");

export const createLoadBalancer = actionCreator.async<
  CreateLoadBalancerParams,
  CreateLoadBalancerResponse,
  unknown
>("CREATE_LOAD_BALANCER");

export const updateLoadBalancer = actionCreator.async<
  UpdateLoadBalancerParams,
  UpdateLoadBalancerResponse,
  unknown
>("UPDATE_LOAD_BALANCER");

export const deleteLoadBalancer = actionCreator.async<
  DeleteLoadBalancerParams,
  void,
  unknown
>("DELETE_LOAD_BALANCER");

// LISTENERS
export const listListeners = actionCreator.async<
  GetListenersListParams,
  GetListenersListResponse,
  unknown
>("LIST_LISTENERS");

export const getListener = actionCreator.async<
  GetListenerDetailsParams,
  GetListenerDetailsResponse,
  unknown
>("GET_LISTENER");

export const createListener = actionCreator.async<
  CreateListenerParams,
  CreateListenerResponse,
  unknown
>("CREATE_LISTENER");

export const updateListener = actionCreator.async<
  UpdateListenerParams,
  UpdateListenerResponse,
  unknown
>("UPDATE_LISTENER");

export const deleteListener = actionCreator.async<
  DeleteListenerParams,
  void,
  unknown
>("DELETE_LISTENER");

// POOLS
export const listPools = actionCreator.async<
  GetPoolsListParams,
  GetPoolsListResponse,
  unknown
>("LIST_POOLS");

export const getPool = actionCreator.async<
  GetPoolDetailsParams,
  GetPoolDetailsResponse,
  unknown
>("GET_POOL");

export const createPool = actionCreator.async<
  CreatePoolParams,
  CreatePoolResponse,
  unknown
>("CREATE_POOL");

export const updatePool = actionCreator.async<
  UpdatePoolParams,
  UpdatePoolResponse,
  unknown
>("UPDATE_POOL");

export const deletePool = actionCreator.async<DeletePoolParams, void, unknown>(
  "DELETE_POOL"
);

// POOL MEMBERS
export const listPoolMembers = actionCreator.async<
  GetPoolMembersListParams,
  GetPoolMembersListResponse,
  unknown
>("LIST_POOL_MEMBERS");

export const getPoolMember = actionCreator.async<
  GetPoolMemberDetailsParams,
  GetPoolMemberDetailsResponse,
  unknown
>("GET_POOL_MEMBER");

export const addPoolMember = actionCreator.async<
  AddPoolMemberParams,
  AddPoolMemberResponse,
  unknown
>("ADD_POOL_MEMBER");

export const updatePoolMember = actionCreator.async<
  UpdatePoolMemberParams,
  UpdatePoolMemberResponse,
  unknown
>("UPDATE_POOL_MEMBER");

export const removePoolMember = actionCreator.async<
  RemovePoolMemberParams,
  void,
  unknown
>("REMOVE_POOL_MEMBER");

// L7 POLICIES
export const listL7Policies = actionCreator.async<
  GetL7PoliciesListParams,
  GetL7PoliciesListResponse,
  unknown
>("LIST_L7_POLICIES");

export const getL7Policy = actionCreator.async<
  GetL7PolicyDetailsParams,
  GetL7PolicyDetailsResponse,
  unknown
>("GET_L7_POLICY");

export const createL7Policy = actionCreator.async<
  CreateL7PolicyParams,
  CreateL7PolicyResponse,
  unknown
>("CREATE_L7_POLICY");

export const updateL7Policy = actionCreator.async<
  UpdateL7PolicyParams,
  UpdateL7PolicyResponse,
  unknown
>("UPDATE_L7_POLICY");

export const deleteL7Policy = actionCreator.async<
  DeleteL7PolicyParams,
  void,
  unknown
>("DELETE_L7_POLICY");

// L7 POLICY RULES
export const listL7PolicyRules = actionCreator.async<
  GetL7RulesListParams,
  GetL7RulesListResponse,
  unknown
>("LIST_L7_POLICY_RULES");

export const getL7PolicyRule = actionCreator.async<
  GetL7RuleDetailsParams,
  GetL7RuleDetailsResponse,
  unknown
>("GET_L7_POLICY_RULE");

export const createL7PolicyRule = actionCreator.async<
  CreateL7RuleParams,
  CreateL7RuleResponse,
  unknown
>("CREATE_L7_POLICY_RULE");

export const updateL7PolicyRule = actionCreator.async<
  UpdateL7RuleParams,
  UpdateL7RuleResponse,
  unknown
>("UPDATE_L7_POLICY_RULE");

export const deleteL7PolicyRule = actionCreator.async<
  DeleteL7RuleParams,
  void,
  unknown
>("DELETE_L7_POLICY_RULE");

// HEALTH MONITORS
export const listHealthMonitors = actionCreator.async<
  GetHealthMonitorsListParams,
  GetHealthMonitorsListResponse,
  unknown
>("LIST_HEALTH_MONITORS");

export const getHealthMonitor = actionCreator.async<
  GetHealthMonitorDetailsParams,
  GetHealthMonitorDetailsResponse,
  unknown
>("GET_HEALTH_MONITOR");

export const createHealthMonitor = actionCreator.async<
  CreateHealthMonitorParams,
  CreateHealthMonitorResponse,
  unknown
>("CREATE_HEALTH_MONITORS");

export const updateHealthMonitor = actionCreator.async<
  UpdateHealthMonitorParams,
  UpdateHealthMonitorResponse,
  unknown
>("UPDATE_HEALTH_MONITORS");

export const deleteHealthMonitor = actionCreator.async<
  DeleteHealthMonitorParams,
  void,
  unknown
>("DELETE_HEALTH_MONITORS");

export const clear = actionCreator<void>("CLEAR");

export const clearPoolsList = actionCreator<void>("CLEAR_POOLS");

export const clearPoolDetails = actionCreator<void>("CLEAR_POOL");

export const clearListenersList = actionCreator<void>("CLEAR_LISTENERS");

export const clearL7PoliciesList = actionCreator<void>("CLEAR_POLICIES");

export const clearMonitorsList = actionCreator<void>("CLEAR_MONITORS");

export const clearMonitorDetails = actionCreator<void>("CLEAR_MONITOR");

export const clearPoolMembersList = actionCreator<void>("CLEAR_POOL_MEMBERS");
