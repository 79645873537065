import { RootReducer } from "../../reducers";
import { createSelector } from "reselect";
import {
  HealthMonitor,
  L7Policy,
  L7Rule,
  Listener,
  LoadBalancer,
  Pool,
  PoolMember,
  TableHealthMonitor,
  TableL7Policy,
  TableL7Rule,
  TableListener,
  TableLoadBalancer,
  TablePool,
  TablePoolMember
} from "./types";
import {
  listenerDraft,
  listenersListDraft,
  listLoadBalancersDraft,
  loadBalancerDraft,
  memdersListDraft,
  monitorDraft,
  monitorsListDraft,
  policiesListDraft,
  policyDraft,
  poolDraft,
  poolMemberDraft,
  poolsListDraft,
  ruleDraft,
  rulesListDraft
} from "./drafts";

// LoadBalancers  __________________________________________________________________
export const loadBalancersSelector = (
  state: RootReducer
): LoadBalancer[] | null => state.loadBalancers.loadBalancers;
// ): LoadBalancer[] | null => listLoadBalancersDraft;

export const areLoadBalancersLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.areLoadBalancersLoading;

export const tableLoadBalancersSelector = createSelector(
  [loadBalancersSelector, (state: RootReducer) => state.networks.floatingIPs],
  (loadBalancers, floatingIPs): TableLoadBalancer[] | null => {
    return (
      loadBalancers?.map((loadBalancer) => {
        const assignedFloatingIP = floatingIPs?.find(
          (ip) => ip.port_id === loadBalancer.vip_port_id
        )?.floating_ip_address;

        return {
          id: loadBalancer.id,
          name: loadBalancer.name,
          // created_at: formatDate(
          //   new Date(loadBalancer.created_at),
          //   DATE_FORMATS.DATETIME
          // ),
          created_at: loadBalancer.created_at,
          description: loadBalancer.description,
          floating_ip: assignedFloatingIP,
          admin_state_string: loadBalancer.admin_state_up ? "up" : "down",
          admin_state_up: loadBalancer.admin_state_up,
          provisioning_status: loadBalancer.provisioning_status,
          operating_status: loadBalancer.operating_status,
          vip_address: loadBalancer.vip_address,
          vip_port_id: loadBalancer.vip_port_id,
          ips_str: assignedFloatingIP
            ? `${loadBalancer.vip_address}, ${assignedFloatingIP}`
            : loadBalancer.vip_address
        };
      }) || null
    );
  }
);

// export const loadBalancerDetailsSelector = (
//   state: RootReducer
//   // ): LoadBalancer | null => state.loadBalancers.loadBalancer;
// ): LoadBalancer | null => loadBalancerDraft;

export const loadBalancerDetailsSelector = createSelector(
  [
    (state: RootReducer) => state.loadBalancers.loadBalancer,
    (state: RootReducer) => state.networks.floatingIPs,
    (state: RootReducer) => state.networks.networks,
    (state: RootReducer) => state.networks.subnets
  ],
  (loadBalancer, floatingIPs, networks, subnets): LoadBalancer | null => {
    if (!loadBalancer) return null;

    const assignedFloatingIP = floatingIPs?.find(
      (ip) => ip.port_id === loadBalancer.vip_port_id
    )?.floating_ip_address;

    const assignedNetwork = networks?.find(
      (net) => net.id === loadBalancer.vip_network_id
    )?.name;

    const assignedSubnet = subnets?.find(
      (sub) => sub.id === loadBalancer.vip_subnet_id
    )?.name;

    return {
      ...loadBalancer,
      network_name: assignedNetwork,
      subnet_name: assignedSubnet,
      floating_ip: assignedFloatingIP,
      ips_str: assignedFloatingIP
        ? `${loadBalancer.vip_address}, ${assignedFloatingIP}`
        : loadBalancer.vip_address
    };
  }
);

export const isLoadBalancerLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isLoadBalancerLoading;

export const isLoadBalancerCreatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isLoadBalancerCreating;

export const isLoadBalancerUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isLoadBalancerUpdating;

export const isLoadBalancerDeletingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isLoadBalancerDeleting;

// Pools  __________________________________________________________________
export const poolsSelector = (state: RootReducer): Pool[] | null =>
  state.loadBalancers.pools;
// poolsListDraft;

export const listenersSelector = (state: RootReducer): Listener[] | null =>
  state.loadBalancers.listeners;
// listenersListDraft;

export const arePoolsLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.arePoolsLoading;

export const tablePoolsSelector = createSelector(
  [poolsSelector, listenersSelector],
  (pools, listeners): TablePool[] | null => {
    return (
      pools?.map((pool) => {
        let matchedListener: Listener | undefined;

        if (pool.listeners?.length && listeners?.length) {
          matchedListener = listeners.find(
            (listener) => listener.id === pool.listeners?.[0]?.id
          );
        }

        return {
          id: pool.id,
          name: pool.name,
          // created_at: formatDate(
          //   new Date(pool.created_at),
          //   DATE_FORMATS.DATETIME
          // ),
          created_at: pool.created_at,
          listener_id: matchedListener?.id,
          listener_name: matchedListener?.name || "",
          description: pool.description,
          lb_algorithm: pool.lb_algorithm,
          protocol: pool.protocol,
          admin_state_string: pool.admin_state_up ? "up" : "down",
          admin_state_up: pool.admin_state_up,
          provisioning_status: pool.provisioning_status,
          operating_status: pool.operating_status,
          session_persistence_type:
            typeof pool.session_persistence === "object"
              ? pool.session_persistence?.type
              : undefined,

          session_persistence_cookie_name:
            typeof pool.session_persistence === "object"
              ? pool.session_persistence?.cookie_name
              : undefined
        };
      }) || null
    );
  }
);

export const poolDetailsSelector = (state: RootReducer): Pool | null =>
  state.loadBalancers.pool;
// poolDraft;

export const isPoolLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolLoading;

export const isPoolCreatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolCreating;

export const isPoolUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolUpdating;

export const isPoolDeletingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolDeleting;

// Listeners  __________________________________________________________________

export const areListenersLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.areListenersLoading;

export const tableListenersSelector = createSelector(
  [listenersSelector, poolsSelector],
  (listeners, pools): TableListener[] | null => {
    if (!Array.isArray(listeners)) return null;

    return listeners.map((listener) => {
      const headers = listener.insert_headers ?? {};

      const checkHeader = (key: string): boolean => headers[key] === "True";

      return {
        id: listener.id,
        name: listener.name,
        description: listener.description,
        // created_at: formatDate(
        //   new Date(listener.created_at),
        //   DATE_FORMATS.DATETIME
        // ),
        created_at: listener.created_at,
        protocol_port: listener.protocol_port,
        protocol: listener.protocol,
        admin_state_string: listener.admin_state_up ? "up" : "down",
        admin_state_up: listener.admin_state_up,
        provisioning_status: listener.provisioning_status,
        operating_status: listener.operating_status,
        connection_limit: listener.connection_limit,
        timeout_client_data: listener.timeout_client_data,
        timeout_member_data: listener.timeout_member_data,
        timeout_member_connect: listener.timeout_member_connect,
        pool_name:
          pools?.find((pool) => pool.id === listener.default_pool_id)?.name ||
          "",
        default_pool_id: listener.default_pool_id,
        x_forwarded_for: checkHeader("X-Forwarded-For"),
        x_forwarded_proto: checkHeader("X-Forwarded-Proto"),
        x_forwarded_port: checkHeader("X-Forwarded-Port")
      };
    });
  }
);

export const listenerDetailsSelector = (state: RootReducer): Listener | null =>
  state.loadBalancers.listener;
// listenerDraft;

export const isListenerLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isListenerLoading;

export const isListenerCreatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isListenerCreating;

export const isListenerUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isListenerUpdating;

export const isListenerDeletingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isListenerDeleting;

// L7 Policies  __________________________________________________________________
export const l7PolicySelector = (state: RootReducer): L7Policy[] | null =>
  state.loadBalancers.l7Policies;
// policiesListDraft;

export const areL7PoliciesLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.areL7PoliciesLoading;

export const tableL7PoliciesSelector = createSelector(
  [l7PolicySelector],
  (policies): TableL7Policy[] | null => {
    return (
      policies?.map((policy) => ({
        id: policy.id,
        name: policy.name,
        // created_at: formatDate(
        //   new Date(policy.created_at),
        //   DATE_FORMATS.DATETIME
        // ),
        created_at: policy.created_at,
        listener_id: policy.listener_id,
        description: policy.description,
        action: policy.action,
        admin_state_string: policy.admin_state_up ? "up" : "down",
        admin_state_up: policy.admin_state_up,
        provisioning_status: policy.provisioning_status,
        operating_status: policy.operating_status,
        position: policy.position,
        redirect_pool_id: policy.redirect_pool_id,
        redirect_url: policy.redirect_url,
        project_id: policy.project_id
      })) || null
    );
  }
);

export const l7PolicyDetailsSelector = (state: RootReducer): L7Policy | null =>
  state.loadBalancers.l7Policy;
// policyDraft;

export const isL7PolicyLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyLoading;

export const isL7PolicyCreatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyCreating;

export const isL7PolicyUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyUpdating;

export const isL7PolicyDeletingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyDeleting;

// L7 Rules  __________________________________________________________________
export const l7RulesSelector = (state: RootReducer): L7Rule[] | null =>
  state.loadBalancers.l7PolicyRules;
// rulesListDraft;

export const areL7RulesLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.areL7PolicyRulesLoading;

export const tableL7RulesSelector = createSelector(
  [l7RulesSelector],
  (rules): TableL7Rule[] | null => {
    return (
      rules?.map((rule) => ({
        id: rule.id,
        // created_at: formatDate(
        //   new Date(rule.created_at),
        //   DATE_FORMATS.DATETIME
        // ),
        created_at: rule.created_at,
        type: rule.type,
        compare_type: rule.compare_type,
        value: rule.value,
        key: rule.key || "-",
        invert: rule.invert,
        invert_string: rule.invert ? "yes" : "no",
        admin_state_string: rule.admin_state_up ? "up" : "down",
        admin_state_up: rule.admin_state_up,
        provisioning_status: rule.provisioning_status,
        operating_status: rule.operating_status,
        project_id: rule.project_id
      })) || null
    );
  }
);

export const l7RuleDetailsSelector = (state: RootReducer): L7Rule | null =>
  state.loadBalancers.l7PolicyRule;
// ruleDraft;

export const isL7RuleLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyRuleLoading;

export const isL7RuleCreatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyRuleCreating;

export const isL7RuleUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyRuleUpdating;

export const isL7RuleDeletingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isL7PolicyRuleDeleting;

// Monitors  __________________________________________________________________
export const monitorsSelector = (state: RootReducer): HealthMonitor[] | null =>
  state.loadBalancers.healthMonitors;
// monitorsListDraft;

export const areMonitorsLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.areHealthMonitorsLoading;

export const tableMonitorsSelector = createSelector(
  [monitorsSelector],
  (monitors): TableHealthMonitor[] | null => {
    return (
      monitors?.map((monitor) => ({
        id: monitor.id,
        name: monitor.name,
        // created_at: formatDate(
        //   new Date(monitor.created_at),
        //   DATE_FORMATS.DATETIME
        // ),
        created_at: monitor.created_at,
        type: monitor.type,
        delay: monitor.delay,
        timeout: monitor.timeout,
        max_retries: monitor.max_retries,
        max_retries_down: monitor.max_retries_down,
        http_method: monitor.http_method,
        url_path: monitor.url_path,
        expected_codes: monitor.expected_codes,
        admin_state_string: monitor.admin_state_up ? "up" : "down",
        admin_state_up: monitor.admin_state_up,
        provisioning_status: monitor.provisioning_status,
        operating_status: monitor.operating_status,
        project_id: monitor.project_id
      })) || null
    );
  }
);

export const monitorDetailsSelector = (
  state: RootReducer
): HealthMonitor | null => state.loadBalancers.healthMonitor;
// ): HealthMonitor | null => monitorDraft;

export const isMonitorLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isHealthMonitorLoading;

export const isMonitorCreatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isHealthMonitorCreating;

export const isMonitorUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isHealthMonitorUpdating;

export const isMonitorDeletingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isHealthMonitorDeleting;

// Pool Members  __________________________________________________________________
export const poolMembersSelector = (state: RootReducer): PoolMember[] | null =>
  state.loadBalancers.poolMembers;
// memdersListDraft;

export const arePoolMembersLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.arePoolMembersLoading;

export const tablePoolMembersSelector = createSelector(
  [poolMembersSelector],
  (members): TablePoolMember[] | null => {
    return (
      members?.map((member) => ({
        id: member.id,
        name: member.name,
        // created_at: formatDate(
        //   new Date(member.created_at),
        //   DATE_FORMATS.DATETIME
        // ),
        created_at: member.created_at,
        weight: member.weight,
        address: member.address,
        monitor_address: member.monitor_address,
        monitor_port: member.monitor_port,
        monitor_address_str: member.monitor_address || "",
        monitor_port_str:
          !member.monitor_port || member.monitor_port === 0
            ? ""
            : String(member.monitor_port),
        subnet_id: member.subnet_id,
        pool_id: member.pool_id,
        backup: member.backup,
        backup_string: member.backup ? "yes" : "no",
        protocol_port: member.protocol_port,
        admin_state_string: member.admin_state_up ? "up" : "down",
        admin_state_up: member.admin_state_up,
        provisioning_status: member.provisioning_status,
        operating_status: member.operating_status,
        project_id: member.project_id
      })) || null
    );
  }
);

export const poolMemberDetailsSelector = (
  state: RootReducer
): PoolMember | null => state.loadBalancers.poolMember;
// poolMemberDraft;

export const isPoolMemberLoadingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolMemberLoading;

export const isPoolMemberAddingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolMemberAdding;

export const isPoolMemberUpdatingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolMemberUpdating;

export const isPoolMemberRemovingSelector = (state: RootReducer): boolean =>
  state.loadBalancers.isPoolMemberRemoving;
