import { IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { TAB_TITLES as LB_TAB_TITLES } from "components/LoadBalancer";
import { TABS as LB_TABS } from "components/LoadBalancer/types";
import {
  FormDialog,
  selectOptionSchema,
  selectOptionSchemaNotRequired
} from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TABLE_SORTING_TYPES
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import * as loadBalancersActions from "modules/loadBalancers/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import { ENTITY_NAME_LENGTH, ROUTES } from "../../constants";
import { DIALOG_TYPES } from "./types";
import {
  L7_POLICY_ACTION,
  L7_POLICY_ACTION_LABELS,
  L7_RULE_COMPARE_TYPES,
  L7_RULE_COMPARE_TYPES_LABELS,
  L7_RULE_TYPES,
  L7_RULE_TYPES_LABELS,
  TableL7Rule
} from "modules/loadBalancers/types";
import {
  areL7RulesLoadingSelector,
  isL7PolicyDeletingSelector,
  isL7PolicyLoadingSelector,
  isL7PolicyUpdatingSelector,
  isL7RuleCreatingSelector,
  isL7RuleDeletingSelector,
  isL7RuleUpdatingSelector,
  isListenerDeletingSelector,
  isListenerLoadingSelector,
  isListenerUpdatingSelector,
  isLoadBalancerDeletingSelector,
  isLoadBalancerLoadingSelector,
  isLoadBalancerUpdatingSelector,
  isMonitorDeletingSelector,
  isMonitorLoadingSelector,
  isMonitorUpdatingSelector,
  isPoolDeletingSelector,
  isPoolLoadingSelector,
  isPoolUpdatingSelector,
  l7PolicyDetailsSelector,
  listenerDetailsSelector,
  loadBalancerDetailsSelector,
  monitorDetailsSelector,
  poolDetailsSelector,
  tableL7RulesSelector
} from "modules/loadBalancers/selectors";
import * as s from "./styles";
import { Loader } from "components/common/Loader";
import { getParentPath } from "utils/getParentPath";

const POLL_ID_PREFIX = "LB_L7POLICY";

const POLL_IDS = {
  loadBalancer: "LB",
  listener: "LISTENER",
  pool: "POOL",
  policy: "POLICY",
  rules: "RULES"
};

const tableL7RulesColumns: TableColumn<TableL7Rule>[] = [
  { key: "id", label: "ID" },
  { key: "type", label: "Type" },
  { key: "compare_type", label: "Compare type" },
  { key: "key", label: "Key" },
  { key: "value", label: "Value" },
  { key: "invert_string", label: "Invert" },
  { key: "operating_status", label: "Operating Status" },
  {
    key: "provisioning_status",
    label: "Provisioning Status"
  },
  {
    key: "admin_state_string",
    label: "Admin State"
  }
];

export const LBL7Policy: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    lbId: string;
    listenerId: string;
    l7PolicyId: string;
  }>();

  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);

  const loadBalancerDetails = useSelector(loadBalancerDetailsSelector);
  const isLoadBalancerUpdating = useSelector(isLoadBalancerUpdatingSelector);
  const isLoadBalancersDeleting = useSelector(isLoadBalancerDeletingSelector);

  const isLoadBalancersOperationInProgress =
    isLoadBalancerUpdating || isLoadBalancersDeleting;
  const previousIsLoadBalancersOperationInProgress = usePrevious(
    isLoadBalancersOperationInProgress
  );

  const listenerDetails = useSelector(listenerDetailsSelector);
  const isListenerLoading = useSelector(isListenerLoadingSelector);
  const headers = listenerDetails?.insert_headers ?? {};
  const checkHeader = (key: string): boolean => headers[key] === "True";
  const isListenerUpdating = useSelector(isListenerUpdatingSelector);
  const isListenerDeleting = useSelector(isListenerDeletingSelector);

  const isListenersOperationInProgress =
    isListenerUpdating || isListenerDeleting;
  const previousIsListenersOperationInProgress = usePrevious(
    isListenersOperationInProgress
  );

  const poolDetails = useSelector(poolDetailsSelector);
  const isPoolLoading = useSelector(isPoolLoadingSelector);
  const isPoolUpdating = useSelector(isPoolUpdatingSelector);
  const isPoolDeleting = useSelector(isPoolDeletingSelector);
  const isPoolsOperationInProgress = isPoolUpdating || isPoolDeleting;
  const previousIsPoolsOperationInProgress = usePrevious(
    isPoolsOperationInProgress
  );

  const monitorDetails = useSelector(monitorDetailsSelector);
  const isMonitorLoading = useSelector(isMonitorLoadingSelector);
  const isMonitorUpdating = useSelector(isMonitorUpdatingSelector);
  const isMonitorDeleting = useSelector(isMonitorDeletingSelector);
  const isMonitorOperationInProgress = isMonitorUpdating || isMonitorDeleting;
  const previousIsMonitorsOperationInProgress = usePrevious(
    isMonitorOperationInProgress
  );

  const l7PolicyDetails = useSelector(l7PolicyDetailsSelector);
  const isL7PolicyLoading = useSelector(isL7PolicyLoadingSelector);
  const isL7PolicyUpdating = useSelector(isL7PolicyUpdatingSelector);
  const isL7PolicyDeleting = useSelector(isL7PolicyDeletingSelector);
  const isL7PoliciesOperationInProgress =
    isL7PolicyUpdating || isL7PolicyDeleting;
  const previousIsL7PoliciesOperationInProgress = usePrevious(
    isL7PoliciesOperationInProgress
  );

  const tableL7Rules = useSelector(tableL7RulesSelector);
  const areL7RulesLoading = useSelector(areL7RulesLoadingSelector);
  const isL7RuleCreating = useSelector(isL7RuleCreatingSelector);
  const isL7RuleUpdating = useSelector(isL7RuleUpdatingSelector);
  const isL7RuleDeleting = useSelector(isL7RuleDeletingSelector);
  const isL7RulesOperationInProgress =
    isL7RuleCreating || isL7RuleUpdating || isL7RuleDeleting;
  const previousIsL7RulesOperationInProgress = usePrevious(
    isL7RulesOperationInProgress
  );

  const title = `L7 Policy: ${listenerDetails?.name}`;

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CERATE_L7RULE, isOpened: false });

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  // const isOperationInProgress =
  //   isNetworkCreating ||
  //   isNetworkUpdating ||
  //   isNetworkDeleting ||
  //   isFloatingIPCreating ||
  //   isFloatingIPUpdating ||
  //   isFloatingIPDeleting ||
  //   isRouterCreating ||
  //   isRouterUpdating ||
  //   isRouterDeleting;
  // const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "LoadBalancers",
      url: generatePath(ROUTES.LOAD_BALANCERS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: loadBalancerDetails?.name || "",
      url: generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId
      })
    },
    {
      text: "Listeners",
      url: `${generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId
      })}?${generateSearchString({
        tab: LB_TAB_TITLES[LB_TABS.LISTENERS]
      })}`
    },
    {
      text: listenerDetails?.name || "",
      url: generatePath(ROUTES.LISTENER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        listenerId: matchParams.listenerId
      })
    },
    {
      text: "L7_Policies",
      url: generatePath(ROUTES.LISTENER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        listenerId: matchParams.listenerId
      })
    },
    {
      text: l7PolicyDetails?.name || "",
      url: generatePath(ROUTES.L7POLICY, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        listenerId: matchParams.listenerId!,
        l7PolicyId: matchParams.l7PolicyId!
      })
    }
  ];

  // const handleEditListenerButtonClick = useCallback(() => {
  //   setDialog({
  //     type: DIALOG_TYPES.EDIT_LISTENER,
  //     isOpened: true
  //   });
  // }, []);

  // const handleEditPoolButtonClick = useCallback(() => {
  //   setDialog({
  //     type: DIALOG_TYPES.EDIT_POOL,
  //     isOpened: true
  //   });
  // }, []);

  const handleEditL7PolicyButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_L7POLICY,
      isOpened: true
    });
  }, []);

  const handleDeleteL7PolicyButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_L7POLICY,
      isOpened: true
    });
  }, []);

  const handleCreateL7RuleButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CERATE_L7RULE,
      isOpened: true
    });
  }, []);

  const handleEditL7RuleMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_L7RULE,
      isOpened: true
    });
  }, []);

  const handleDeleteL7RuleMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_L7RULE,
      isOpened: true
    });
  }, []);

  const tableL7RulesActions: TableRowActionsMenuItem<TableL7Rule>[] = [
    {
      label: "Edit",
      handler: handleEditL7RuleMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    },
    {
      label: "Delete",
      handler: handleDeleteL7RuleMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    }
  ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      networksActions.getSubnets.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      loadBalancersActions.getLoadBalancer.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        lbId: matchParams.lbId!
      })
    );
    dispatch(
      loadBalancersActions.getListener.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        lbId: matchParams.lbId!,
        listenerId: matchParams.listenerId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.policy}`,
        action: loadBalancersActions.getL7Policy.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          listenerId: matchParams.listenerId!,
          l7PolicyId: matchParams.l7PolicyId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.rules}`,
        action: loadBalancersActions.listL7PolicyRules.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          l7PolicyId: matchParams.l7PolicyId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(loadBalancersActions.clear());
  });

  // useEffect(() => {
  //   if (previousIsOperationInProgress && !isOperationInProgress) {
  //     dispatch(
  //       networksActions.getNetworks.started({
  //         regionId: matchParams.regionId!,
  //         projectId: matchParams.projectId!
  //       })
  //     );
  //   }
  // }, [
  //   previousIsOperationInProgress,
  //   isOperationInProgress,
  //   dispatch,
  //   matchParams.projectId,
  //   matchParams.regionId
  // ]);

  useEffect(() => {
    if (
      previousIsL7PoliciesOperationInProgress &&
      !isL7PoliciesOperationInProgress
    ) {
      if (!l7PolicyDetails) {
        history(getParentPath(location.pathname, 2));
      } else {
        dispatch(
          loadBalancersActions.getL7Policy.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            listenerId: matchParams.listenerId!,
            l7PolicyId: matchParams.l7PolicyId!
          })
        );
        dispatch(
          loadBalancersActions.listL7PolicyRules.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            l7PolicyId: matchParams.l7PolicyId!
          })
        );
      }
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    history,
    matchParams.listenerId,
    previousIsL7PoliciesOperationInProgress,
    isL7PoliciesOperationInProgress,
    l7PolicyDetails,
    matchParams.l7PolicyId
  ]);

  // useEffect(() => {
  //   if (listenerDetails && listenerDetails.default_pool_id) {
  //     dispatch(
  //       loadBalancersActions.getPool.started({
  //         regionId: matchParams.regionId!,
  //         projectId: matchParams.projectId!,
  //         lbId: matchParams.lbId!,
  //         poolId: listenerDetails.default_pool_id
  //       })
  //     );
  //   }
  // }, [
  //   dispatch,
  //   matchParams.projectId,
  //   matchParams.regionId,
  //   history,
  //   listenerDetails,
  //   matchParams.lbId
  // ]);

  // useEffect(() => {
  //   if (poolDetails && poolDetails.healthmonitor_id) {
  //     dispatch(
  //       loadBalancersActions.getHealthMonitor.started({
  //         regionId: matchParams.regionId!,
  //         projectId: matchParams.projectId!,
  //         poolId: poolDetails.id,
  //         monitorId: poolDetails.healthmonitor_id
  //       })
  //     );
  //   }
  // }, [
  //   dispatch,
  //   matchParams.projectId,
  //   matchParams.regionId,
  //   history,
  //   poolDetails?.healthmonitor_id,
  //   poolDetails
  // ]);

  // useEffect(() => {
  //   if (previousIsPoolsOperationInProgress && !isPoolsOperationInProgress) {
  //     loadBalancersActions.listPools.started({
  //       regionId: matchParams.regionId!,
  //       projectId: matchParams.projectId!,
  //       lbId: matchParams.lbId!
  //     });
  //   }
  // }, [
  //   previousIsPoolsOperationInProgress,
  //   isPoolsOperationInProgress,
  //   dispatch,
  //   matchParams.projectId,
  //   matchParams.regionId,
  //   matchParams.lbId
  // ]);

  useEffect(() => {
    if (
      previousIsL7PoliciesOperationInProgress &&
      !isL7PoliciesOperationInProgress
    ) {
      dispatch(
        loadBalancersActions.listL7Policies.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          listenerId: matchParams.listenerId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsL7PoliciesOperationInProgress,
    matchParams.listenerId,
    isL7PoliciesOperationInProgress
  ]);

  const handleConfirmEditL7Policy = useCallback(
    (data: {
      name?: string;
      description?: string;
      action?: string;
      redirect_pool_id?: SelectOption;
      redirect_url?: string;
      position?: number;
      policy_admin_state_up?: boolean;
    }) => {
      dispatch(
        loadBalancersActions.updateL7Policy.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          listenerId: matchParams.listenerId!,
          l7PolicyId: matchParams.l7PolicyId!,
          data: {
            name: data.name,
            description: data.description?.trim(),
            action: data.action,
            redirect_pool: data.redirect_pool_id?.value,
            redirect_url: data.redirect_url,
            position: data.position,
            admin_state_up: data.policy_admin_state_up
          }
        })
      );
      handleCloseDialog();
    },
    [
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.listenerId,
      matchParams.l7PolicyId
    ]
  );

  const handleConfirmCreateL7Rule = useCallback(
    (data: {
      type: string;
      compare_type: string;
      value: string;
      key?: string;
      invert: boolean;
      rule_admin_state_up?: boolean;
    }) => {
      dispatch(
        loadBalancersActions.createL7PolicyRule.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          l7PolicyId: matchParams.l7PolicyId!,
          data: {
            type: data.type,
            compare_type: data.compare_type,
            value: data.value,
            key: data.key,
            invert: data.invert,
            admin_state_up: true
          }
        })
      );
      handleCloseDialog();
    },
    [
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.l7PolicyId
    ]
  );

  const handleConfirmEditL7Rule = useCallback(
    (data: {
      type?: string;
      compare_type?: string;
      value?: string;
      key?: string;
      invert?: boolean;
      rule_admin_state_up?: boolean;
    }) => {
      if (selectedItemId) {
        dispatch(
          loadBalancersActions.updateL7PolicyRule.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            l7PolicyId: matchParams.l7PolicyId!,
            ruleId: selectedItemId,
            data: {
              type: data.type,
              compare_type: data.compare_type,
              value: data.value,
              key: data.key,
              invert: data.invert,
              admin_state_up: data.rule_admin_state_up
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.l7PolicyId
    ]
  );

  const handleConfirmDeleteL7Rule = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        loadBalancersActions.deleteL7PolicyRule.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          l7PolicyId: matchParams.l7PolicyId!,
          ruleId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.l7PolicyId
  ]);

  const handleConfirmDeleteL7Policy = useCallback(() => {
    dispatch(
      loadBalancersActions.deleteL7Policy.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        listenerId: matchParams.listenerId!,
        l7PolicyId: matchParams.l7PolicyId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.listenerId,
    matchParams.l7PolicyId,
    handleCloseDialog
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingL7RuleName = tableL7Rules?.find(
    (rule) => rule.id === deletingItemId
  )?.id;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CERATE_L7RULE]: {
      onConfirm: handleConfirmCreateL7Rule,
      title: "Create Rule",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Type",
          helperText: `Select L7 Rule Type:\n\nCOOKIE - Matches a specific cookie.\nHEADER - Compares a request header.\nFILE TYPE - Checks the file extension.\nPATH - Matches the request path.\nHOST NAME - Compares the request hostname.`,
          options: Object.keys(L7_RULE_TYPES_LABELS).map((x) => ({
            label: L7_RULE_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: L7_RULE_TYPES.HOST_NAME,
          dependent_fields: ["compare_type"],
          rules: string()
        },
        {
          name: "compare_type",
          type: FIELD_TYPES.SELECT,
          label: "Compare Type",
          helperText: `Specify the comparison method: exact match, substring, or regex.`,
          // options: Object.keys(L7_RULE_COMPARE_TYPES_LABELS).map((x) => ({
          //   label: L7_RULE_COMPARE_TYPES_LABELS[x],
          //   value: x
          // })),
          options: (fieldValues) => {
            const allowedOptions =
              fieldValues.type === L7_RULE_TYPES.FILE_TYPE
                ? [L7_RULE_COMPARE_TYPES.REGEX, L7_RULE_COMPARE_TYPES.EQUAL_TO]
                : Object.keys(L7_RULE_COMPARE_TYPES_LABELS);

            return allowedOptions.map((x) => ({
              label: L7_RULE_COMPARE_TYPES_LABELS[x],
              value: x
            }));
          },
          // defaultValue: {
          //   label: L7_RULE_COMPARE_TYPES_LABELS.REGEX,
          //   value: L7_RULE_COMPARE_TYPES.REGEX
          // },
          rules: selectOptionSchema
        },
        {
          name: "value",
          type: FIELD_TYPES.TEXT,
          label: "Value",
          helperText:
            "Set value to use for the comparison. For example, the file type to compare.",
          rules: string()
        },
        {
          name: "key",
          type: FIELD_TYPES.TEXT,
          label: "Key",
          helperText:
            "Set key to use for the comparison. For example, the name of the cookie to evaluate.",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.type ||
              (fieldValues.type !== L7_RULE_TYPES.HEADER &&
                fieldValues.type !== L7_RULE_TYPES.COOKIE)
            );
          },
          rules: string()
        },
        {
          name: "invert",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: false,
          label: "Invert rule logic"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_L7RULE]: {
      onConfirm: handleConfirmEditL7Rule,
      title: "Edit Rule",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Type",
          helperText: `Select L7 Rule Type:\n\nCOOKIE - Matches a specific cookie.\nHEADER - Compares a request header.\nFILE TYPE - Checks the file extension.\nPATH - Matches the request path.\nHOST NAME - Compares the request hostname.`,
          options: Object.keys(L7_RULE_TYPES_LABELS).map((x) => ({
            label: L7_RULE_TYPES_LABELS[x],
            value: x
          })),
          dependent_fields: ["compare_type"],
          defaultValue:
            tableL7Rules?.find((p) => p.id === selectedItemId)?.type ||
            L7_RULE_TYPES.HOST_NAME,
          rules: string()
        },
        {
          name: "compare_type",
          type: FIELD_TYPES.SELECT,
          label: "Compare Type",
          helperText: `Specify the comparison method: exact match, substring, or regex.`,
          options: (fieldValues) => {
            const allowedOptions =
              fieldValues.type === L7_RULE_TYPES.FILE_TYPE
                ? [L7_RULE_COMPARE_TYPES.REGEX, L7_RULE_COMPARE_TYPES.EQUAL_TO]
                : Object.keys(L7_RULE_COMPARE_TYPES_LABELS);

            return allowedOptions.map((x) => ({
              label: L7_RULE_COMPARE_TYPES_LABELS[x],
              value: x
            }));
          },
          defaultValue: (() => {
            const selectedRule = tableL7Rules?.find(
              (p) => p.id === selectedItemId
            );
            return selectedRule?.compare_type
              ? {
                  label:
                    L7_RULE_COMPARE_TYPES_LABELS[selectedRule.compare_type],
                  value: selectedRule.compare_type
                }
              : {
                  label:
                    L7_RULE_COMPARE_TYPES_LABELS[L7_RULE_COMPARE_TYPES.REGEX],
                  value: L7_RULE_COMPARE_TYPES.REGEX
                };
          })(),
          rules: selectOptionSchema
        },
        {
          name: "value",
          type: FIELD_TYPES.TEXT,
          label: "Value",
          defaultValue: tableL7Rules?.find((p) => p.id === selectedItemId)
            ?.value,
          helperText:
            "Set value to use for the comparison. For example, file type to compare.",
          rules: string()
        },
        {
          name: "key",
          type: FIELD_TYPES.TEXT,
          label: "Key",
          defaultValue: tableL7Rules?.find((p) => p.id === selectedItemId)?.key,
          helperText:
            "Set key to use for the comparison. For example, name of the cookie to evaluate.",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.type ||
              (fieldValues.type !== L7_RULE_TYPES.HEADER &&
                fieldValues.type !== L7_RULE_TYPES.COOKIE)
            );
          },
          rules: string()
        },
        {
          name: "invert",
          type: FIELD_TYPES.TOGGLE,
          defaultValue:
            tableL7Rules?.find((p) => p.id === selectedItemId)?.invert || false,
          label: "Invert Rule Logic"
        },
        {
          name: "rule_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tableL7Rules?.find((p) => p.id === selectedItemId)
            ?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_L7POLICY]: {
      onConfirm: handleConfirmEditL7Policy,
      title: "Edit L7Policy",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: l7PolicyDetails?.name || "",
          rules: string().test({
            name: "validateName",
            test: validateName(ENTITY_NAME_LENGTH)
          })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: l7PolicyDetails?.description || "",
          rules: string()
        },
        {
          name: "position",
          type: FIELD_TYPES.NUMBER,
          label: "Position",
          defaultValue: l7PolicyDetails?.position || 1,
          helperText: "The order in which the listener applies policy.",
          min: 1,
          rules: number()
            .integer()
            .notRequired()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(1, "The value must be a number greater than or equal to 1.")
        },
        {
          name: "action",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Action",
          helperText: `Set policy action to determine request flow:\n\nREJECT -  Blocks request with a response code.\nREDIRECT TO URL - Redirects to a specified URL.\nREDIRECT TO POOL - Redirects to specified pool.`,
          options: Object.keys(L7_POLICY_ACTION_LABELS).map((x) => ({
            label: L7_POLICY_ACTION_LABELS[x],
            value: x
          })),
          defaultValue: l7PolicyDetails?.action || L7_POLICY_ACTION.REJECT,
          rules: string()
        },
        {
          name: "redirect_url",
          type: FIELD_TYPES.TEXT,
          label: "Redirect URL",
          defaultValue: l7PolicyDetails?.redirect_url || "",
          helperText:
            "Set the URL to which requests matching this policy will be redirected.",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.action ||
              fieldValues.action !== L7_POLICY_ACTION.REDIRECT_TO_URL
            );
          },
          rules: string()
            .notRequired()
            .url("The redirect URL must be a valid HTTP or HTTPS URL.")
            .matches(
              /^https?:\/\/.+/i,
              "The redirect URL must be a valid HTTP or HTTPS URL and start with http:// or https://"
            )
        },
        {
          name: "redirect_pool_id",
          type: FIELD_TYPES.SELECT,
          label: "Redirect Pool",
          helperText:
            "Set the pool to which requests matching this policy will be redirected.",
          variant: "outlined",
          // options: tablePools?.map((pool) => ({
          //   label: pool.name,
          //   value: pool.id
          // })),
          defaultValue: l7PolicyDetails?.redirect_url,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.action ||
              fieldValues.action !== L7_POLICY_ACTION.REDIRECT_TO_POOL
            );
          },
          rules: selectOptionSchemaNotRequired
        },
        {
          name: "policy_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: l7PolicyDetails?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.DELETE_L7POLICY]: {
      onConfirm: handleConfirmDeleteL7Policy,
      title: `Are you sure you want to delete current L7Policy?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_L7RULE]: {
      onConfirm: handleConfirmDeleteL7Rule,
      title: `Are you sure you want to delete "${
        deletingL7RuleName ?? "selected"
      }" rule?`,
      confirmButtonLabel: "Delete"
    }
  };

  return (
    <>
      <Head title={title} />
      {l7PolicyDetails ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>Policy ID: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {l7PolicyDetails?.id}
                </s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Position:</s.DetailsTitle>
                <s.Tag label={l7PolicyDetails.position} />
                <s.DetailsTitle>Action:</s.DetailsTitle>
                <s.Tag label={l7PolicyDetails.action} />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Operating Status:</s.DetailsTitle>
                <s.Tag label={l7PolicyDetails.operating_status} />
                <s.DetailsTitle>Provisioning Status:</s.DetailsTitle>
                <s.Tag label={l7PolicyDetails.provisioning_status} />
              </s.SummaryRow>
              {l7PolicyDetails.redirect_pool_id && (
                <s.SummaryRow>
                  <s.DetailsTitle>Redirect Pool:</s.DetailsTitle>
                  <s.DetailsInfo>
                    {l7PolicyDetails.redirect_pool_id}
                  </s.DetailsInfo>
                </s.SummaryRow>
              )}
              {l7PolicyDetails.redirect_url && (
                <s.SummaryRow>
                  <s.DetailsTitle>Redirect URL:</s.DetailsTitle>
                  <s.DetailsInfo>{l7PolicyDetails.redirect_url}</s.DetailsInfo>
                </s.SummaryRow>
              )}
              {l7PolicyDetails.description && (
                <s.SummaryRow>
                  <s.DetailsTitle>Description:</s.DetailsTitle>
                  <s.DetailsInfo>{l7PolicyDetails.description}</s.DetailsInfo>
                </s.SummaryRow>
              )}
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditL7PolicyButtonClick}
                    color={"inherit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteL7PolicyButtonClick}
                    color={"inherit"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          <Table
            key={"l7RulesTable"}
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={tableL7Rules || []}
            columns={tableL7RulesColumns}
            actions={tableL7RulesActions}
            isLoading={!tableL7Rules}
            toolbarItems={
              <Button
                onClick={handleCreateL7RuleButtonClick}
                variant={"contained"}
              >
                Create L7Rule
              </Button>
            }
          />

          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
