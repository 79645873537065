export enum LB_PROTOCOLS {
  TCP = "TCP",
  UDP = "UDP",
  HTTP = "HTTP",
  HTTPS = "HTTPS"
}

export const LB_PROTOCOLS_LABELS = {
  [LB_PROTOCOLS.TCP]: "TCP",
  [LB_PROTOCOLS.UDP]: "UDP",
  [LB_PROTOCOLS.HTTP]: "HTTP",
  [LB_PROTOCOLS.HTTPS]: "HTTPS"
};

export enum POOL_PROTOCOLS {
  TCP = "TCP",
  UDP = "UDP",
  PROXY = "PROXY",
  HTTP = "HTTP",
  HTTPS = "HTTPS"
}

export const POOL_PROTOCOLS_LABELS = {
  [POOL_PROTOCOLS.TCP]: "TCP",
  [POOL_PROTOCOLS.UDP]: "UDP",
  [POOL_PROTOCOLS.PROXY]: "PROXY",
  [POOL_PROTOCOLS.HTTP]: "HTTP",
  [POOL_PROTOCOLS.HTTPS]: "HTTPS"
};

export enum LB_POOL_ALGORITHM {
  LEAST_CONNECTIONS = "LEAST_CONNECTIONS",
  ROUND_ROBIN = "ROUND_ROBIN",
  SOURCE_IP = "SOURCE_IP"
}

export const LB_POOL_ALGORITHM_LABELS = {
  [LB_POOL_ALGORITHM.LEAST_CONNECTIONS]: "LEAST CONNECTIONS",
  [LB_POOL_ALGORITHM.ROUND_ROBIN]: "ROUND ROBIN",
  [LB_POOL_ALGORITHM.SOURCE_IP]: "SOURCE IP"
};

export enum LB_POOL_SESSION_PERSISTENCE {
  SOURCE_IP = "SOURCE_IP",
  HTTP_COOKIE = "HTTP_COOKIE",
  APP_COOKIE = "APP_COOKIE",
  None = "None"
}

export const LB_POOL_SESSION_PERSISTENCE_LABELS = {
  [LB_POOL_SESSION_PERSISTENCE.SOURCE_IP]: "SOURCE IP",
  [LB_POOL_SESSION_PERSISTENCE.HTTP_COOKIE]: "HTTP COOKIE",
  [LB_POOL_SESSION_PERSISTENCE.APP_COOKIE]: "APP COOKIE",
  [LB_POOL_SESSION_PERSISTENCE.None]: "None"
};

export enum LB_MONITOR_TYPES {
  HTTP = "HTTP",
  HTTPS = "HTTPS",
  PING = "PING",
  TCP = "TCP",
  TLS_HELLO = "TLS-HELLO",
  UPD_CONNECT = "UPD-CONNECT"
}

export const LB_MONITOR_TYPES_LABELS = {
  [LB_MONITOR_TYPES.HTTP]: "HTTP",
  [LB_MONITOR_TYPES.HTTPS]: "HTTPS",
  [LB_MONITOR_TYPES.PING]: "PING",
  [LB_MONITOR_TYPES.TCP]: "TCP",
  [LB_MONITOR_TYPES.TLS_HELLO]: "TLS-HELLO",
  [LB_MONITOR_TYPES.UPD_CONNECT]: "UPD-CONNECT"
};

export enum LB_MONITOR_HTTP_METHODS {
  GET = "GET",
  HEAD = "HEAD",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  TRACE = "TRACE",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  CONNECT = "CONNECT"
}

export const LB_MONITOR_HTTP_METHODS_LABELS = {
  [LB_MONITOR_HTTP_METHODS.GET]: "GET",
  [LB_MONITOR_HTTP_METHODS.HEAD]: "HEAD",
  [LB_MONITOR_HTTP_METHODS.POST]: "POST",
  [LB_MONITOR_HTTP_METHODS.PUT]: "PUT",
  [LB_MONITOR_HTTP_METHODS.DELETE]: "DELETE",
  [LB_MONITOR_HTTP_METHODS.TRACE]: "TRACE",
  [LB_MONITOR_HTTP_METHODS.OPTIONS]: "OPTIONS",
  [LB_MONITOR_HTTP_METHODS.PATCH]: "PATCH",
  [LB_MONITOR_HTTP_METHODS.CONNECT]: "CONNECT"
};

export enum L7_POLICY_ACTION {
  REJECT = "REJECT",
  REDIRECT_TO_URL = "REDIRECT_TO_URL",
  REDIRECT_TO_POOL = "REDIRECT_TO_POOL"
}

export const L7_POLICY_ACTION_LABELS = {
  [L7_POLICY_ACTION.REJECT]: "REJECT",
  [L7_POLICY_ACTION.REDIRECT_TO_URL]: "REDIRECT TO URL",
  [L7_POLICY_ACTION.REDIRECT_TO_POOL]: "REDIRECT TO POOL"
};

export enum L7_RULE_TYPES {
  HOST_NAME = "HOST_NAME",
  PATH = "PATH",
  FILE_TYPE = "FILE_TYPE",
  HEADER = "HEADER",
  COOKIE = "COOKIE"
}

export const L7_RULE_TYPES_LABELS = {
  [L7_RULE_TYPES.HOST_NAME]: "HOST NAME",
  [L7_RULE_TYPES.PATH]: "PATH",
  [L7_RULE_TYPES.FILE_TYPE]: "FILE TYPE",
  [L7_RULE_TYPES.HEADER]: "HEADER",
  [L7_RULE_TYPES.COOKIE]: "COOKIE"
};

export enum L7_RULE_COMPARE_TYPES {
  REGEX = "REGEX",
  EQUAL_TO = "EQUAL_TO",
  STARTS_WITH = "STARTS_WITH",
  ENDS_WITH = "ENDS_WITH",
  CONTAINS = "CONTAINS"
}

export const L7_RULE_COMPARE_TYPES_LABELS = {
  [L7_RULE_COMPARE_TYPES.REGEX]: "REGEX",
  [L7_RULE_COMPARE_TYPES.EQUAL_TO]: "EQUAL TO",
  [L7_RULE_COMPARE_TYPES.STARTS_WITH]: "STARTS WITH",
  [L7_RULE_COMPARE_TYPES.ENDS_WITH]: "ENDS WITH",
  [L7_RULE_COMPARE_TYPES.CONTAINS]: "CONTAINS"
};

// HealthMonitors
export type HealthMonitor = {
  id: string;
  name: string;
  created_at: string;
  project_id: string;
  type: string;
  delay: number;
  timeout: number;
  max_retries: number;
  max_retries_down: number;
  http_method: string;
  url_path: string;
  expected_codes: string;
  admin_state_up: boolean;
  pools?: { id: string }[] | null;
  provisioning_status: string;
  operating_status: string;
};

export type TableHealthMonitor = {
  id: string;
  name: string;
  created_at: string;
  project_id: string;
  type: string;
  delay: number;
  timeout: number;
  max_retries: number;
  max_retries_down: number;
  http_method: string;
  url_path: string;
  expected_codes: string;
  admin_state_up: boolean;
  pools?: { id: string }[] | null;
  provisioning_status: string;
  operating_status: string;
};

// get HealthMonitors list
export type GetHealthMonitorsListParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  pollingId?: string;
};
export type GetHealthMonitorsListResponse = HealthMonitor[];

// get HealthMonitor details
export type GetHealthMonitorDetailsParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  monitorId: string;
  pollingId?: string;
};
export type GetHealthMonitorDetailsResponse = HealthMonitor;

// create HealthMonitor
export type CreateHealthMonitorParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  data: {
    type: string; // "HTTPS";
    delay: number;
    timeout: number;
    max_retries: number;
    max_retries_down: number;
    url_path: string; // "/health";
    expected_codes: string; // "200,201";
    name: string;
    http_method?: string; // "GET";
    admin_state_up: boolean;
  };
};

export type CreateHealthMonitorResponse = HealthMonitor;

// update HealthMonitor
export type UpdateHealthMonitorParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  monitorId: string;
  data: {
    delay?: number;
    timeout?: number;
    max_retries?: number;
    max_retries_down?: number;
    url_path?: string;
    expected_codes?: string;
    name?: string;
    http_method?: string; // "GET"; // if not specified - will be treated as GET
    admin_state_up?: boolean;
  };
};

export type UpdateHealthMonitorResponse = HealthMonitor;

// delete HealthMonitor
export type DeleteHealthMonitorParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  monitorId: string;
};

// L7Rules __________________________________________________________________
export type L7Rule = {
  id: string;
  created_at: string;
  type: string;
  compare_type: string;
  value: string;
  project_id: string;
  key: string | null;
  invert: boolean;
  admin_state_up: boolean;
  provisioning_status: string;
  operating_status: string;
};

export type TableL7Rule = {
  id: string;
  created_at: string;
  type: string;
  compare_type: string;
  value: string;
  project_id: string;
  key: string | null;
  invert: boolean;
  invert_string: string;
  admin_state_up: boolean;
  admin_state_string: string;
  provisioning_status: string;
  operating_status: string;
};

// get L7Rules list
export type GetL7RulesListParams = {
  regionId: string;
  projectId: string;
  l7PolicyId: string;
  pollingId?: string;
};
export type GetL7RulesListResponse = L7Rule[];

// get L7Rules details
export type GetL7RuleDetailsParams = {
  regionId: string;
  projectId: string;
  l7PolicyId: string;
  ruleId: string;
  pollingId?: string;
};
export type GetL7RuleDetailsResponse = L7Rule;

// create L7Rule
export type CreateL7RuleParams = {
  regionId: string;
  projectId: string;
  l7PolicyId: string;
  data: {
    invert: boolean;
    type: string; // "PATH";
    compare_type: string; // "CONTAINS";
    key?: string;
    value: string;
    admin_state_up: boolean;
  };
};

export type CreateL7RuleResponse = L7Rule;

// update L7Rule
export type UpdateL7RuleParams = {
  regionId: string;
  projectId: string;
  l7PolicyId: string;
  ruleId: string;
  data: {
    invert?: boolean;
    type?: string;
    compare_type?: string;
    key?: string;
    value?: string;
    admin_state_up?: boolean;
  };
};

export type UpdateL7RuleResponse = L7Rule;

// delete L7Rule
export type DeleteL7RuleParams = {
  regionId: string;
  projectId: string;
  l7PolicyId: string;
  ruleId: string;
};

// Policies __________________________________________________________________

export type L7Policy = {
  id: string;
  name: string;
  created_at: string;
  listener_id: string;
  action: string;
  position: number;
  description?: string;
  project_id: string;
  redirect_pool_id?: string | null;
  redirect_prefix?: string | null;
  redirect_url?: string | null;
  redirect_http_code?: number | null;
  admin_state_up: boolean;
  provisioning_status: string;
  operating_status: string;
  rules?: L7Rule[] | null | { id: string }[];
};

export type TableL7Policy = {
  id: string;
  name: string;
  description?: string;
  created_at: string;
  listener_id: string;
  action: string;
  position: number;
  project_id: string;
  admin_state_up: boolean;
  admin_state_string: string;
  provisioning_status: string;
  operating_status: string;
  redirect_pool_id?: string | null;
  redirect_url?: string | null;
};

// get Policies list
export type GetL7PoliciesListParams = {
  regionId: string;
  projectId: string;
  listenerId: string;
  pollingId?: string;
};
export type GetL7PoliciesListResponse = L7Policy[];

// get Policy details
export type GetL7PolicyDetailsParams = {
  regionId: string;
  projectId: string;
  listenerId: string;
  l7PolicyId: string;
  pollingId?: string;
};
export type GetL7PolicyDetailsResponse = L7Policy;

// create Policy
export type CreateL7PolicyParams = {
  regionId: string;
  projectId: string;
  listenerId: string;
  data: {
    name: string;
    description?: string;
    action: string; //"REDIRECT_PREFIX";
    redirect_url?: string; //"https://google.com";
    redirect_pool?: string;
    position?: number;
    redirect_prefix?: string;
    redirect_http_code?: number;
    admin_state_up?: boolean;
  };
};

export type CreateL7PolicyResponse = L7Policy;

// update Policy
export type UpdateL7PolicyParams = {
  regionId: string;
  projectId: string;
  listenerId: string;
  l7PolicyId: string;
  data: {
    name?: string;
    description?: string;
    action?: string; //"REDIRECT_PREFIX";
    redirect_url?: string; //"https://google.com";
    redirect_pool?: string;
    position?: number;
    redirect_prefix?: string;
    redirect_http_code?: number;
    admin_state_up?: boolean;
  };
};

export type UpdateL7PolicyResponse = L7Policy;

// delete Policy
export type DeleteL7PolicyParams = {
  regionId: string;
  projectId: string;
  listenerId: string;
  l7PolicyId: string;
};

// PoolMembers  __________________________________________________________________
export type PoolMember = {
  id: string;
  name: string;
  created_at: string;
  weight: number;
  admin_state_up: boolean;
  project_id: string;
  subnet_id: string;
  pool_id?: string | null;
  address: string;
  protocol_port: number;
  provisioning_status: string;
  operating_status: string;
  backup: boolean;
  monitor_address?: string | null;
  monitor_port?: number | null;
  tags?: string[] | null;
};

export type TablePoolMember = {
  id: string;
  name: string;
  created_at: string;
  weight: number;
  admin_state_up: boolean;
  admin_state_string: string;
  project_id: string;
  subnet_id: string;
  pool_id?: string | null;
  address: string;
  protocol_port: number;
  provisioning_status: string;
  operating_status: string;
  backup: boolean;
  backup_string: string;
  monitor_address?: string | null;
  monitor_port?: number | null;
  monitor_address_str?: string | null;
  monitor_port_str?: string | null;
};

// get PoolMembers list
export type GetPoolMembersListParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  pollingId?: string;
};
export type GetPoolMembersListResponse = PoolMember[];

// get PoolMember details
export type GetPoolMemberDetailsParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  memberId: string;
  pollingId?: string;
};
export type GetPoolMemberDetailsResponse = PoolMember;

// add PoolMember
export type AddPoolMemberParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  data: {
    name: string;
    subnet_id?: string;
    address: string; // "124.0.0.72";
    protocol_port: number; // 80;
    weight: number;
    backup: boolean;
    tags?: string[];
  };
};

export type AddPoolMemberResponse = PoolMember;

// update PoolMember
export type UpdatePoolMemberParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  memberId: string;
  data: {
    name?: string;
    weight?: number;
    monitor_port?: number;
    monitor_address?: string;
    admin_state_up?: boolean;
    backup?: boolean;
    tags?: string[];
  };
};

export type UpdatePoolMemberResponse = PoolMember;

// delete PoolMember
export type RemovePoolMemberParams = {
  regionId: string;
  projectId: string;
  poolId: string;
  memberId: string;
};

// Pools  __________________________________________________________________
export type Pool = {
  id: string;
  name: string;
  lb_algorithm: string;
  protocol: string;
  description: string;
  created_at: string;
  project_id: string;
  subnet_id?: string;
  admin_state_up: boolean;
  provisioning_status: string;
  operating_status: string;
  healthmonitor_id?: string | null;
  session_persistence?: {
    type?: string;
    cookie_name?: string;
  } | null;
  provider?: string;
  listeners?: { id: string }[] | null;
  loadbalancers?: { id: string }[] | null;
  members?: PoolMember[] | null | { id: string }[];
  healthmonitor?: {
    id: string;
    name: string;
    project_id: string;
    type: string;
    delay: number;
    timeout: number;
    max_retries: number;
    max_retries_down: number;
    http_method: string;
    url_path: string;
    expected_codes: string;
    admin_state_up: boolean;
    status: string;
    pools?: null;
    provisioning_status: string;
    operating_status: string;
  };
  tags?: string[] | null;
};

export type TablePool = {
  id: string;
  name: string;
  created_at: string;
  lb_algorithm: string;
  protocol: string;
  description: string;
  listener_id?: string;
  listener_name?: string;
  admin_state_up: boolean;
  admin_state_string: string;
  provisioning_status: string;
  operating_status: string;
  session_persistence_type?: string;
  session_persistence_cookie_name?: string;
};

// get Pools list
export type GetPoolsListParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  pollingId?: string;
};
export type GetPoolsListResponse = Pool[];

// get Pool details
export type GetPoolDetailsParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  poolId: string;
  pollingId?: string;
};
export type GetPoolDetailsResponse = Pool;

// create Pool
export type CreatePoolParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  data: {
    name: string;
    description?: string;
    listener_id?: string;
    protocol: string | null; // "HTTP";
    lb_method: string; // "ROUND_ROBIN";
    persistence?: { type?: string; cookie_name?: string } | null; // {"type": "APP_COOKIE", "cookie_name": "session_cookie"},
    admin_state_up?: boolean;
    tags?: string[];
  };
};
export type CreatePoolResponse = Pool;

// update Pool
export type UpdatePoolParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  poolId: string;
  data: {
    name?: string;
    description?: string;
    lb_method?: string; // "ROUND_ROBIN";
    persistence?: { type?: string; cookie_name?: string } | null; // {"type": "APP_COOKIE", "cookie_name": "session_cookie"},
    admin_state_up?: boolean;
    tags?: string[];
  };
};

export type UpdatePoolResponse = Pool;

// delete Pool
export type DeletePoolParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  poolId: string;
};

// Listeners  __________________________________________________________________
export type Listener = {
  id: string;
  project_id: string;
  name: string;
  created_at: string;
  description: string;
  protocol: string;
  protocol_port: number;
  default_pool_id?: string | null;
  default_pool?: null;
  loadbalancers?: { id: string }[] | null;
  connection_limit: number;
  sni_container_refs: string[] | null;
  default_tls_container_ref?: string | null;
  admin_state_up: boolean;
  pools?: null;
  l7policies?: L7Policy[] | null;
  provisioning_status: string;
  timeout_client_data?: number;
  timeout_member_data?: number;
  timeout_member_connect?: number;
  timeout_tcp_inspect?: number;
  insert_headers?: Record<string, string | undefined> | null;
  allowed_cidrs?: string[] | null;
  tls_ciphers?: string;
  tls_versions?: string[] | null;
  tags?: string[] | null;
  alpn_protocols?: string[] | null;
  client_authentication?: string;
  client_ca_tls_container_ref?: string;
  client_crl_container_ref?: string;
  operating_status: string;
};

export type TableListener = {
  id: string;
  name: string;
  created_at: string;
  protocol_port: number;
  protocol: string;
  default_pool_id?: string | null;
  pool_name?: string;
  description: string;
  admin_state_up: boolean;
  admin_state_string: string;
  provisioning_status: string;
  operating_status: string;
  connection_limit: number;
  timeout_client_data?: number;
  timeout_member_data?: number;
  timeout_member_connect?: number;
  x_forwarded_for?: boolean;
  x_forwarded_proto?: boolean;
  x_forwarded_port?: boolean;
};

// get Listeners list
export type GetListenersListParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  pollingId?: string;
};
export type GetListenersListResponse = Listener[];

// get Listener details
export type GetListenerDetailsParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  listenerId: string;
  pollingId?: string;
};
export type GetListenerDetailsResponse = Listener;

// create Listener
export type CreateListenerParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  data: {
    name: string;
    description?: string;
    protocol: string;
    admin_state_up?: boolean;
    protocol_port?: number;
    connection_limit: number;
    timeout_client_data?: number;
    timeout_member_connect?: number;
    timeout_member_data?: number;
    default_pool_id?: string;
    // "timeout_tcp_inspect": "",
    insert_headers?: Record<string, string | undefined> | null; // {"X-Forwarded-For": "True"}
    // "allowed_cidrs": "",
    tags?: string[];
  };
};

export type CreateListenerResponse = Listener;

// update Listener
export type UpdateListenerParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  listenerId: string;
  data: {
    name?: string;
    description?: string;
    admin_state_up?: boolean;
    connection_limit?: number;
    default_pool_id?: string;
    timeout_client_data?: number;
    timeout_member_connect?: number;
    timeout_member_data?: number;
    // "timeout_tcp_inspect": "",
    insert_headers?: Record<string, string | undefined> | null; // {"X-Forwarded-For": "True"}
    // "allowed_cidrs": "",
    tags?: string[];
  };
};

export type UpdateListenerResponse = Listener;

// delete Listener
export type DeleteListenerParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  listenerId: string;
};

// LoadBalancers  __________________________________________________________________
export type LoadBalancer = {
  id: string;
  name: string;
  description: string;
  created_at: string;
  admin_state_up: boolean;
  project_id: string;
  provisioning_status: string;
  operating_status: string;
  floating_ip?: string;
  ips_str?: string;
  vip_address: string;
  vip_port_id: string;
  vip_subnet_id: string;
  subnet_name?: string;
  network_name?: string;
  vip_network_id: string;
  vip_qos_policy_id: string | null;
  flavor_id: string | null;
  availability_zone: string | null;
  provider: string;
  listeners: Listener[] | { id: string }[];
  pools: Pool[] | { id: string }[];
  tags?: string[] | null;
};

export type TableLoadBalancer = {
  id: string;
  name: string;
  created_at: string;
  admin_state_up: boolean;
  admin_state_string: string;
  provisioning_status: string;
  operating_status: string;
  vip_address: string;
  vip_port_id: string;
  description: string;
  ips_str: string;
  floating_ip?: string;
};

// get LoadBalancers list
export type GetLoadBalancersListParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};
export type GetLoadBalancersListResponse = LoadBalancer[];

// get LoadBalancer details
export type GetLoadBalancerDetailsParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  pollingId?: string;
};
export type GetLoadBalancerDetailsResponse = LoadBalancer;

// create LoadBalancer
export type CreateLoadBalancerParams = {
  regionId: string;
  projectId: string;
  data: {
    name: string;
    description: string;
    subnet_id: string;
    admin_state_up: boolean;
    tags?: string[];
    listener: {
      name?: string;
      description?: string;
      protocol: string; // "HTTP";
      admin_state_up?: boolean;
      protocol_port?: number; // 433;
      connection_limit?: number;
      timeout_client_data?: number;
      timeout_member_connect?: number;
      timeout_member_data?: number;
      // "timeout_tcp_inspect": "",
      insert_headers?: Record<string, string | undefined> | null; // {"X-Forwarded-For": "True"}
      // "allowed_cidrs": "",
      tags?: string[];
      pool: {
        name?: string;
        description?: string;
        listener_id?: string;
        protocol?: string; // : "HTTP";
        lb_method: string; // "ROUND_ROBIN";
        persistence?: { type?: string; cookie_name?: string } | null; //{ type: "APP_COOKIE"; cookie_name: "session_cookie" };
        admin_state_up?: boolean;
        monitor: {
          name?: string;
          type: string; // "HTTPS";
          delay: number;
          timeout: number;
          max_retries: number;
          max_retries_down: number;
          http_method: string; // "GET";
          url_path?: string;
          expected_codes?: string; // "200,201";
          admin_state_up: boolean;
        };
      };
    };
  };
};

export type CreateLoadBalancerResponse = LoadBalancer;

// update LoadBalancer
export type UpdateLoadBalancerParams = {
  regionId: string;
  projectId: string;
  lbId: string;
  data: {
    name?: string;
    description?: string;
    admin_state_up?: boolean;
    tags?: string[];
  };
};

export type UpdateLoadBalancerResponse = LoadBalancer;

// delete LoadBalancer
export type DeleteLoadBalancerParams = {
  regionId: string;
  projectId: string;
  lbId: string;
};
