import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  HealthMonitor,
  L7Policy,
  L7Rule,
  Listener,
  LoadBalancer,
  Pool,
  PoolMember
} from "./types";

export type Reducer = {
  loadBalancers: LoadBalancer[] | null;
  areLoadBalancersLoading: boolean;
  loadBalancer: LoadBalancer | null;
  isLoadBalancerLoading: boolean;
  isLoadBalancerCreating: boolean;
  isLoadBalancerUpdating: boolean;
  isLoadBalancerDeleting: boolean;

  listeners: Listener[] | null;
  areListenersLoading: boolean;
  listener: Listener | null;
  isListenerLoading: boolean;
  isListenerCreating: boolean;
  isListenerUpdating: boolean;
  isListenerDeleting: boolean;

  pools: Pool[] | null;
  arePoolsLoading: boolean;
  pool: Pool | null;
  isPoolLoading: boolean;
  isPoolCreating: boolean;
  isPoolUpdating: boolean;
  isPoolDeleting: boolean;

  poolMembers: PoolMember[] | null;
  arePoolMembersLoading: boolean;
  poolMember: PoolMember | null;
  isPoolMemberLoading: boolean;
  isPoolMemberAdding: boolean;
  isPoolMemberUpdating: boolean;
  isPoolMemberRemoving: boolean;

  l7Policies: L7Policy[] | null;
  areL7PoliciesLoading: boolean;
  l7Policy: L7Policy | null;
  isL7PolicyLoading: boolean;
  isL7PolicyCreating: boolean;
  isL7PolicyUpdating: boolean;
  isL7PolicyDeleting: boolean;

  l7PolicyRules: L7Rule[] | null;
  areL7PolicyRulesLoading: boolean;
  l7PolicyRule: L7Rule | null;
  isL7PolicyRuleLoading: boolean;
  isL7PolicyRuleCreating: boolean;
  isL7PolicyRuleUpdating: boolean;
  isL7PolicyRuleDeleting: boolean;

  healthMonitors: HealthMonitor[] | null;
  areHealthMonitorsLoading: boolean;
  healthMonitor: HealthMonitor | null;
  isHealthMonitorLoading: boolean;
  isHealthMonitorCreating: boolean;
  isHealthMonitorUpdating: boolean;
  isHealthMonitorDeleting: boolean;
};

const initialState: Reducer = {
  loadBalancers: null,
  areLoadBalancersLoading: false,
  loadBalancer: null,
  isLoadBalancerLoading: false,
  isLoadBalancerCreating: false,
  isLoadBalancerUpdating: false,
  isLoadBalancerDeleting: false,

  listeners: null,
  areListenersLoading: false,
  listener: null,
  isListenerLoading: false,
  isListenerCreating: false,
  isListenerUpdating: false,
  isListenerDeleting: false,

  pools: null,
  arePoolsLoading: false,
  pool: null,
  isPoolLoading: false,
  isPoolCreating: false,
  isPoolUpdating: false,
  isPoolDeleting: false,

  poolMembers: null,
  arePoolMembersLoading: false,
  poolMember: null,
  isPoolMemberLoading: false,
  isPoolMemberAdding: false,
  isPoolMemberUpdating: false,
  isPoolMemberRemoving: false,

  l7Policies: null,
  areL7PoliciesLoading: false,
  l7Policy: null,
  isL7PolicyLoading: false,
  isL7PolicyCreating: false,
  isL7PolicyUpdating: false,
  isL7PolicyDeleting: false,

  l7PolicyRules: null,
  areL7PolicyRulesLoading: false,
  l7PolicyRule: null,
  isL7PolicyRuleLoading: false,
  isL7PolicyRuleCreating: false,
  isL7PolicyRuleUpdating: false,
  isL7PolicyRuleDeleting: false,

  healthMonitors: null,
  areHealthMonitorsLoading: false,
  healthMonitor: null,
  isHealthMonitorLoading: false,
  isHealthMonitorCreating: false,
  isHealthMonitorUpdating: false,
  isHealthMonitorDeleting: false
};

export const reducer = reducerWithInitialState(initialState)
  // Load Balancers
  .case(
    actions.listLoadBalancers.started,
    (state): Reducer => ({
      ...state,
      areLoadBalancersLoading: true
    })
  )
  .case(
    actions.listLoadBalancers.done,
    (state, payload): Reducer => ({
      ...state,
      loadBalancers: payload.result,
      areLoadBalancersLoading: false
    })
  )
  .case(
    actions.listLoadBalancers.failed,
    (state): Reducer => ({
      ...state,
      areLoadBalancersLoading: false
    })
  )

  .case(
    actions.getLoadBalancer.started,
    (state): Reducer => ({
      ...state,
      isLoadBalancerLoading: true
    })
  )
  .case(
    actions.getLoadBalancer.done,
    (state, payload): Reducer => ({
      ...state,
      loadBalancer: payload.result,
      isLoadBalancerLoading: false
    })
  )
  .case(
    actions.getLoadBalancer.failed,
    (state): Reducer => ({
      ...state,
      isLoadBalancerLoading: false
    })
  )

  .case(
    actions.createLoadBalancer.started,
    (state): Reducer => ({
      ...state,
      isLoadBalancerCreating: true
    })
  )
  .case(
    actions.createLoadBalancer.done,
    (state): Reducer => ({
      ...state,
      isLoadBalancerCreating: false
    })
  )
  .case(
    actions.createLoadBalancer.failed,
    (state): Reducer => ({
      ...state,
      isLoadBalancerCreating: false
    })
  )

  .case(
    actions.updateLoadBalancer.started,
    (state): Reducer => ({
      ...state,
      isLoadBalancerUpdating: true
    })
  )
  .case(
    actions.updateLoadBalancer.done,
    (state): Reducer => ({
      ...state,
      isLoadBalancerUpdating: false
    })
  )
  .case(
    actions.updateLoadBalancer.failed,
    (state): Reducer => ({
      ...state,
      isLoadBalancerUpdating: false
    })
  )

  .case(
    actions.deleteLoadBalancer.started,
    (state): Reducer => ({
      ...state,
      isLoadBalancerDeleting: true
    })
  )
  .case(
    actions.deleteLoadBalancer.done,
    (state): Reducer => ({
      ...state,
      isLoadBalancerDeleting: false,
      loadBalancer: null
    })
  )
  .case(
    actions.deleteLoadBalancer.failed,
    (state): Reducer => ({
      ...state,
      isLoadBalancerDeleting: false
    })
  )

  // Listeners
  .case(
    actions.listListeners.started,
    (state): Reducer => ({
      ...state,
      areListenersLoading: true
    })
  )
  .case(
    actions.listListeners.done,
    (state, payload): Reducer => ({
      ...state,
      listeners: payload.result,
      areListenersLoading: false
    })
  )
  .case(
    actions.listListeners.failed,
    (state): Reducer => ({
      ...state,
      areListenersLoading: false
    })
  )

  .case(
    actions.getListener.started,
    (state): Reducer => ({
      ...state,
      isListenerLoading: true
    })
  )
  .case(
    actions.getListener.done,
    (state, payload): Reducer => ({
      ...state,
      listener: payload.result,
      isListenerLoading: false
    })
  )
  .case(
    actions.getListener.failed,
    (state): Reducer => ({
      ...state,
      isListenerLoading: false
    })
  )

  .case(
    actions.createListener.started,
    (state): Reducer => ({
      ...state,
      isListenerCreating: true
    })
  )
  .case(
    actions.createListener.done,
    (state): Reducer => ({
      ...state,
      isListenerCreating: false
    })
  )
  .case(
    actions.createListener.failed,
    (state): Reducer => ({
      ...state,
      isListenerCreating: false
    })
  )

  .case(
    actions.updateListener.started,
    (state): Reducer => ({
      ...state,
      isListenerUpdating: true
    })
  )
  .case(
    actions.updateListener.done,
    (state): Reducer => ({
      ...state,
      isListenerUpdating: false
    })
  )
  .case(
    actions.updateListener.failed,
    (state): Reducer => ({
      ...state,
      isListenerUpdating: false
    })
  )

  .case(
    actions.deleteListener.started,
    (state): Reducer => ({
      ...state,
      isListenerDeleting: true
    })
  )
  .case(
    actions.deleteListener.done,
    (state): Reducer => ({
      ...state,
      isListenerDeleting: false,
      listener: null
    })
  )
  .case(
    actions.deleteListener.failed,
    (state): Reducer => ({
      ...state,
      isListenerDeleting: false
    })
  )

  // Pools
  .case(
    actions.listPools.started,
    (state): Reducer => ({
      ...state,
      arePoolsLoading: true
    })
  )
  .case(
    actions.listPools.done,
    (state, payload): Reducer => ({
      ...state,
      pools: payload.result,
      arePoolsLoading: false
    })
  )
  .case(
    actions.listPools.failed,
    (state): Reducer => ({
      ...state,
      arePoolsLoading: false
    })
  )

  .case(
    actions.getPool.started,
    (state): Reducer => ({
      ...state,
      isPoolLoading: true
    })
  )
  .case(
    actions.getPool.done,
    (state, payload): Reducer => ({
      ...state,
      pool: payload.result,
      isPoolLoading: false
    })
  )
  .case(
    actions.getPool.failed,
    (state): Reducer => ({
      ...state,
      isPoolLoading: false
    })
  )

  .case(
    actions.createPool.started,
    (state): Reducer => ({
      ...state,
      isPoolCreating: true
    })
  )
  .case(
    actions.createPool.done,
    (state): Reducer => ({
      ...state,
      isPoolCreating: false
    })
  )
  .case(
    actions.createPool.failed,
    (state): Reducer => ({
      ...state,
      isPoolCreating: false
    })
  )

  .case(
    actions.updatePool.started,
    (state): Reducer => ({
      ...state,
      isPoolUpdating: true
    })
  )
  .case(
    actions.updatePool.done,
    (state): Reducer => ({
      ...state,
      isPoolUpdating: false
    })
  )
  .case(
    actions.updatePool.failed,
    (state): Reducer => ({
      ...state,
      isPoolUpdating: false
    })
  )

  .case(
    actions.deletePool.started,
    (state): Reducer => ({
      ...state,
      isPoolDeleting: true
    })
  )
  .case(
    actions.deletePool.done,
    (state): Reducer => ({
      ...state,
      isPoolDeleting: false,
      pool: null
    })
  )
  .case(
    actions.deletePool.failed,
    (state): Reducer => ({
      ...state,
      isPoolDeleting: false
    })
  )

  // Pool Members
  .case(
    actions.listPoolMembers.started,
    (state): Reducer => ({
      ...state,
      arePoolMembersLoading: true
    })
  )
  .case(
    actions.listPoolMembers.done,
    (state, payload): Reducer => ({
      ...state,
      poolMembers: payload.result,
      arePoolMembersLoading: false
    })
  )
  .case(
    actions.listPoolMembers.failed,
    (state): Reducer => ({
      ...state,
      arePoolMembersLoading: false
    })
  )

  .case(
    actions.getPoolMember.started,
    (state): Reducer => ({
      ...state,
      isPoolMemberLoading: true
    })
  )
  .case(
    actions.getPoolMember.done,
    (state, payload): Reducer => ({
      ...state,
      poolMember: payload.result,
      isPoolMemberLoading: false
    })
  )
  .case(
    actions.getPoolMember.failed,
    (state): Reducer => ({
      ...state,
      isPoolMemberLoading: false
    })
  )

  .case(
    actions.addPoolMember.started,
    (state): Reducer => ({
      ...state,
      isPoolMemberAdding: true
    })
  )
  .case(
    actions.addPoolMember.done,
    (state): Reducer => ({
      ...state,
      isPoolMemberAdding: false
    })
  )
  .case(
    actions.addPoolMember.failed,
    (state): Reducer => ({
      ...state,
      isPoolMemberAdding: false
    })
  )

  .case(
    actions.updatePoolMember.started,
    (state): Reducer => ({
      ...state,
      isPoolMemberUpdating: true
    })
  )
  .case(
    actions.updatePoolMember.done,
    (state): Reducer => ({
      ...state,
      isPoolMemberUpdating: false
    })
  )
  .case(
    actions.updatePoolMember.failed,
    (state): Reducer => ({
      ...state,
      isPoolMemberUpdating: false
    })
  )

  .case(
    actions.removePoolMember.started,
    (state): Reducer => ({
      ...state,
      isPoolMemberRemoving: true
    })
  )
  .case(
    actions.removePoolMember.done,
    (state): Reducer => ({
      ...state,
      isPoolMemberRemoving: false,
      poolMember: null
    })
  )
  .case(
    actions.removePoolMember.failed,
    (state): Reducer => ({
      ...state,
      isPoolMemberRemoving: false
    })
  )

  // L7 Policies
  .case(
    actions.listL7Policies.started,
    (state): Reducer => ({
      ...state,
      areL7PoliciesLoading: true
    })
  )
  .case(
    actions.listL7Policies.done,
    (state, payload): Reducer => ({
      ...state,
      l7Policies: payload.result,
      areL7PoliciesLoading: false
    })
  )
  .case(
    actions.listL7Policies.failed,
    (state): Reducer => ({
      ...state,
      areL7PoliciesLoading: false
    })
  )

  .case(
    actions.getL7Policy.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyLoading: true
    })
  )
  .case(
    actions.getL7Policy.done,
    (state, payload): Reducer => ({
      ...state,
      l7Policy: payload.result,
      isL7PolicyLoading: false
    })
  )
  .case(
    actions.getL7Policy.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyLoading: false
    })
  )

  .case(
    actions.createL7Policy.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyCreating: true
    })
  )
  .case(
    actions.createL7Policy.done,
    (state): Reducer => ({
      ...state,
      isL7PolicyCreating: false
    })
  )
  .case(
    actions.createL7Policy.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyCreating: false
    })
  )

  .case(
    actions.updateL7Policy.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyUpdating: true
    })
  )
  .case(
    actions.updateL7Policy.done,
    (state): Reducer => ({
      ...state,
      isL7PolicyUpdating: false
    })
  )
  .case(
    actions.updateL7Policy.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyUpdating: false
    })
  )

  .case(
    actions.deleteL7Policy.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyDeleting: true
    })
  )
  .case(
    actions.deleteL7Policy.done,
    (state): Reducer => ({
      ...state,
      isL7PolicyDeleting: false,
      l7Policy: null
    })
  )
  .case(
    actions.deleteL7Policy.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyDeleting: false
    })
  )

  // L7 Policy Rules
  .case(
    actions.listL7PolicyRules.started,
    (state): Reducer => ({
      ...state,
      areL7PolicyRulesLoading: true
    })
  )
  .case(
    actions.listL7PolicyRules.done,
    (state, payload): Reducer => ({
      ...state,
      l7PolicyRules: payload.result,
      areL7PolicyRulesLoading: false
    })
  )
  .case(
    actions.listL7PolicyRules.failed,
    (state): Reducer => ({
      ...state,
      areL7PolicyRulesLoading: false
    })
  )

  .case(
    actions.getL7PolicyRule.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleLoading: true
    })
  )
  .case(
    actions.getL7PolicyRule.done,
    (state, payload): Reducer => ({
      ...state,
      l7PolicyRule: payload.result,
      isL7PolicyRuleLoading: false
    })
  )
  .case(
    actions.getL7PolicyRule.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleLoading: false
    })
  )

  .case(
    actions.createL7PolicyRule.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleCreating: true
    })
  )
  .case(
    actions.createL7PolicyRule.done,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleCreating: false
    })
  )
  .case(
    actions.createL7PolicyRule.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleCreating: false
    })
  )

  .case(
    actions.updateL7PolicyRule.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleUpdating: true
    })
  )
  .case(
    actions.updateL7PolicyRule.done,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleUpdating: false
    })
  )
  .case(
    actions.updateL7PolicyRule.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleUpdating: false
    })
  )

  .case(
    actions.deleteL7PolicyRule.started,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleDeleting: true
    })
  )
  .case(
    actions.deleteL7PolicyRule.done,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleDeleting: false,
      l7PolicyRule: null
    })
  )
  .case(
    actions.deleteL7PolicyRule.failed,
    (state): Reducer => ({
      ...state,
      isL7PolicyRuleDeleting: false
    })
  )

  // Health Monitors
  .case(
    actions.listHealthMonitors.started,
    (state): Reducer => ({
      ...state,
      areHealthMonitorsLoading: true
    })
  )
  .case(
    actions.listHealthMonitors.done,
    (state, payload): Reducer => ({
      ...state,
      healthMonitors: payload.result,
      areHealthMonitorsLoading: false
    })
  )
  .case(
    actions.listHealthMonitors.failed,
    (state): Reducer => ({
      ...state,
      areHealthMonitorsLoading: false
    })
  )

  .case(
    actions.getHealthMonitor.started,
    (state): Reducer => ({
      ...state,
      isHealthMonitorLoading: true
    })
  )
  .case(
    actions.getHealthMonitor.done,
    (state, payload): Reducer => ({
      ...state,
      healthMonitor: payload.result,
      isHealthMonitorLoading: false
    })
  )
  .case(
    actions.getHealthMonitor.failed,
    (state): Reducer => ({
      ...state,
      isHealthMonitorLoading: false
    })
  )

  .case(
    actions.createHealthMonitor.started,
    (state): Reducer => ({
      ...state,
      isHealthMonitorCreating: true
    })
  )
  .case(
    actions.createHealthMonitor.done,
    (state): Reducer => ({
      ...state,
      isHealthMonitorCreating: false
    })
  )
  .case(
    actions.createHealthMonitor.failed,
    (state): Reducer => ({
      ...state,
      isHealthMonitorCreating: false
    })
  )

  .case(
    actions.updateHealthMonitor.started,
    (state): Reducer => ({
      ...state,
      isHealthMonitorUpdating: true
    })
  )
  .case(
    actions.updateHealthMonitor.done,
    (state): Reducer => ({
      ...state,
      isHealthMonitorUpdating: false
    })
  )
  .case(
    actions.updateHealthMonitor.failed,
    (state): Reducer => ({
      ...state,
      isHealthMonitorUpdating: false
    })
  )

  .case(
    actions.deleteHealthMonitor.started,
    (state): Reducer => ({
      ...state,
      isHealthMonitorDeleting: true
    })
  )
  .case(
    actions.deleteHealthMonitor.done,
    (state): Reducer => ({
      ...state,
      isHealthMonitorDeleting: false,
      healthMonitor: null
    })
  )
  .case(
    actions.deleteHealthMonitor.failed,
    (state): Reducer => ({
      ...state,
      isHealthMonitorDeleting: false
    })
  )

  .case(
    actions.clearPoolsList,
    (state): Reducer => ({
      ...state,
      pools: null
    })
  )

  .case(
    actions.clearListenersList,
    (state): Reducer => ({
      ...state,
      pools: null
    })
  )

  .case(
    actions.clearL7PoliciesList,
    (state): Reducer => ({
      ...state,
      l7Policies: null
    })
  )

  .case(
    actions.clearMonitorsList,
    (state): Reducer => ({
      ...state,
      healthMonitors: null
    })
  )

  .case(
    actions.clearMonitorDetails,
    (state): Reducer => ({
      ...state,
      healthMonitor: null
    })
  )

  .case(
    actions.clearPoolDetails,
    (state): Reducer => ({
      ...state,
      healthMonitor: null,
      pool: null,
      poolMembers: null,
      isPoolLoading: false
    })
  )

  .case(
    actions.clearPoolMembersList,
    (state): Reducer => ({
      ...state,
      poolMembers: null
    })
  )

  .case(actions.clear, (): Reducer => initialState);
