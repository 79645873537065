import { Dayjs } from "dayjs";
import { ReactNode } from "react";
import { AnySchema } from "yup";

export enum FIELD_TYPES {
  TEXT = "text",
  FILE = "file",
  MULTILINE_TEXT = "multiline_text",
  PASSWORD = "password",
  SELECT = "select",
  MULTISELECT = "multiselect",
  CHECKBOX = "checkbox",
  NUMBER = "number",
  LABEL = "label",
  NOTES = "notes",
  TOGGLE_BUTTON = "toggle_button",
  GROUPED_SELECT = "grouped_select",
  MULTISELECT_GROUPED = "multiselect_grouped",
  TABLE_SELECT = "table_select",
  TABLE_MULTISELECT = "table_multiselect",
  TOGGLE = "toggle",
  DIVIDER = "divider",
  TIME_PICKER = "time_picker",
  STATIC_TIME_PICKER = "static_time_picker",
  DATE_PICKER = "data_picker",
  MOBILE_DATE_PICKER2 = "mobile_data_picker2"
}

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectOptionWIthIcon = {
  label: string;
  value: string;
  icon?: JSX.Element;
};

export type ToggleButtonGroupValue = SelectOption | null;

export type FormDialogField = {
  label: ((fieldValues: Record<string, unknown>) => string) | string;
  labelPicker?: ReactNode;
  link?: string;
  name: string;
  type: FIELD_TYPES;
  defaultValue?:
    | ((fieldValues: Record<string, unknown>) => unknown)
    // | unknown
    | string
    | string[]
    | number
    | boolean
    | SelectOption[]
    | SelectOption
    | Date
    | Dayjs
    | null
    | {
        [key in keyof SelectOption]?: string;
      };
  options?:
    | ((
        fieldValues: Record<string, unknown>
      ) => SelectOption[] | undefined | null)
    | SelectOption[]
    | SelectOptionWIthIcon[];
  suffix?: string;
  rules?: AnySchema;
  onCreate?: () => void;
  createText?: string;
  step?:
    | ((fieldValues: Record<string, unknown>) => number | undefined)
    | number;
  min?: ((fieldValues: Record<string, unknown>) => number | undefined) | number;
  max?: ((fieldValues: Record<string, unknown>) => number | undefined) | number;
  isHidden?: (fieldValues: Record<string, unknown>) => boolean;
  autocomplete?: string;
  freeSolo?: boolean;
  helperText?: string | ReactNode;
  dependent_fields?: string[];
  minDate?: Date | Dayjs;
  maxDate?: Date | Dayjs;
  fullWidth?: boolean;
  width?: string;
  margin?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  readOnly?: boolean;
  variant?: "standard" | "outlined";
  groupMap?: Map<
    string,
    {
      group: string;
      isHidden: boolean;
    }
  >;
};

export type FormDialogProps = {
  title: string;
  fields?: FormDialogField[];
  yupNoSortEdges?: [string, string][];
  onConfirm: (data?) => void;
  onCancel: () => void;
  isOpened: boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  isLocked?: boolean;
  isLoading?: boolean;
};

export type FormData = {
  [key: string]: any;
};
