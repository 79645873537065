import { watcherSaga as authSaga } from "modules/auth/sagas";
import { watcherSaga as billingSaga } from "modules/billing/sagas";
import { watcherSaga as clustersSaga } from "modules/clusters/sagas";
import { watcherSaga as databasesSaga } from "modules/databases/sagas";
import { watcherSaga as enterprisesSaga } from "modules/enterprises/sagas";
import { watcherSaga as instancesSaga } from "modules/instances/sagas";
import { watcherSaga as networksSaga } from "modules/networks/sagas";
import { watcherSaga as newsSaga } from "modules/news/sagas";
import { watcherSaga as nlpSaga } from "modules/nlp/sagas";
import { watcherSaga as objectStorageSaga } from "modules/objectStorage/sagas";
import { watcherSaga as pollingSaga } from "modules/polling/sagas";
import { watcherSaga as projectsSaga } from "modules/projects/sagas";
import { watcherSaga as securitySaga } from "modules/security/sagas";
import { watcherSaga as storageSaga } from "modules/storage/sagas";
import { watcherSaga as supportSaga } from "modules/support/sagas";
import { watcherSaga as backupsSaga } from "modules/backups/sagas";
import { watcherSaga as loadBalancersSaga } from "modules/loadBalancers/sagas";
import { all, AllEffect } from "redux-saga/effects";

export function* rootSaga(): Generator<AllEffect<unknown>, void, unknown> {
  yield all([
    authSaga(),
    clustersSaga(),
    instancesSaga(),
    storageSaga(),
    backupsSaga(),
    supportSaga(),
    networksSaga(),
    projectsSaga(),
    securitySaga(),
    enterprisesSaga(),
    pollingSaga(),
    billingSaga(),
    databasesSaga(),
    objectStorageSaga(),
    loadBalancersSaga(),
    nlpSaga(),
    newsSaga()
  ]);
}
