import { IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu } from "components/common/Menu";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import {
  FormDialog,
  selectOptionSchema,
  selectOptionSchemaNotRequired
} from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TABLE_SORTING_TYPES
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import * as loadBalancersActions from "modules/loadBalancers/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  floatingIPsSelector,
  isFloatingIPUpdatingSelector
} from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  MAX_LB_TIMEOUT,
  MAX_PORT,
  MIN_PORT,
  ROUTES
} from "../../constants";
import { DIALOG_TYPES, TABS } from "./types";
import {
  LB_POOL_ALGORITHM,
  LB_POOL_ALGORITHM_LABELS,
  LB_POOL_SESSION_PERSISTENCE,
  LB_POOL_SESSION_PERSISTENCE_LABELS,
  LB_PROTOCOLS,
  LB_PROTOCOLS_LABELS,
  POOL_PROTOCOLS,
  POOL_PROTOCOLS_LABELS,
  TableListener,
  TablePool
} from "modules/loadBalancers/types";
import {
  areListenersLoadingSelector,
  arePoolsLoadingSelector,
  isListenerCreatingSelector,
  isListenerDeletingSelector,
  isListenerUpdatingSelector,
  isLoadBalancerDeletingSelector,
  isLoadBalancerLoadingSelector,
  isLoadBalancerUpdatingSelector,
  isPoolCreatingSelector,
  isPoolDeletingSelector,
  isPoolUpdatingSelector,
  listenersSelector,
  loadBalancerDetailsSelector,
  poolsSelector,
  tableListenersSelector,
  tablePoolsSelector
} from "modules/loadBalancers/selectors";
import * as s from "./styles";
import { getSelectOption } from "utils/getSelectOption";
import { Loader } from "components/common/Loader";
import { getParentPath } from "utils/getParentPath";
import {
  filterAvailablePoolProtocols,
  filterListenersByPoolProtocol,
  filterPoolsByListenerProtocol
} from "utils/filterProtocols";

const POLL_ID_PREFIX = "LOAD_BALANCER";

const POLL_IDS = {
  loadBalancer: "LOAD_BALANCER",
  listeners: "LISTENERS",
  pools: "POOLS",
  floating_ips: "FLOATING_IPS"
};

const tableListenersColumns: TableColumn<TableListener>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "protocol", label: "Protocol" },
  { key: "protocol_port", label: "Port" },
  { key: "pool_name", label: "Pool" },
  {
    key: "provisioning_status",
    label: "Provisioning Status"
  },
  {
    key: "admin_state_string",
    label: "Admin State"
  }
];

const tablePoolsColumns: TableColumn<TablePool>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "protocol", label: "Protocol" },
  { key: "lb_algorithm", label: "Algorithm" },
  { key: "session_persistence_type", label: "Session Persistence" },
  { key: "listener_name", label: "Listener" },
  {
    key: "provisioning_status",
    label: "Provisioning Status"
  },
  {
    key: "admin_state_string",
    label: "Admin State"
  }
];

export const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.LISTENERS]: "Listeners",
  [TABS.POOLS]: "Pools"
};

export const LoadBalancer: FC = () => {
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.LISTENERS) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const dispatch = useDispatch();
  const history = useNavigate();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    lbId: string;
  }>();

  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const floatingIPs = useSelector(floatingIPsSelector);
  const isFloatingIPUpdating = useSelector(isFloatingIPUpdatingSelector);

  const loadBalancerDetails = useSelector(loadBalancerDetailsSelector);
  const isLoadBalancerUpdating = useSelector(isLoadBalancerUpdatingSelector);
  const isLoadBalancersDeleting = useSelector(isLoadBalancerDeletingSelector);

  const isLoadBalancersOperationInProgress =
    isLoadBalancerUpdating || isLoadBalancersDeleting || isFloatingIPUpdating;
  const previousIsLoadBalancersOperationInProgress = usePrevious(
    isLoadBalancersOperationInProgress
  );

  const tableListeners = useSelector(tableListenersSelector);
  const listeners = useSelector(listenersSelector);
  const isListenerCreating = useSelector(isListenerCreatingSelector);
  const isListenerUpdating = useSelector(isListenerUpdatingSelector);
  const isListenerDeleting = useSelector(isListenerDeletingSelector);

  const isListenersOperationInProgress =
    isListenerCreating || isListenerUpdating || isListenerDeleting;
  const previousIsListenersOperationInProgress = usePrevious(
    isListenersOperationInProgress
  );

  const pools = useSelector(poolsSelector);
  const tablePools = useSelector(tablePoolsSelector);
  const isPoolCreating = useSelector(isPoolCreatingSelector);
  const isPoolUpdating = useSelector(isPoolUpdatingSelector);
  const isPoolDeleting = useSelector(isPoolDeletingSelector);
  const isPoolsOperationInProgress =
    isPoolCreating || isPoolUpdating || isPoolDeleting;
  const previousIsPoolsOperationInProgress = usePrevious(
    isPoolsOperationInProgress
  );

  const title = `LB: ${loadBalancerDetails?.name}`;

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_LISTENER, isOpened: false });

  const [isCreatePoolInnerDialogOpened, setIsCreatePoolInnerDialogOpened] =
    useState(false);

  const handleCloseCreatePoolInnerDialog = useCallback(() => {
    setIsCreatePoolInnerDialogOpened(false);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleActionsMenuButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      actionsMenuButtonRef.current = e.currentTarget;
      setIsActionsMenuOpened(!isActionsMenuOpened);
    },
    [isActionsMenuOpened]
  );

  const handleActionsMenuClose = useCallback(() => {
    setIsActionsMenuOpened(false);
  }, []);

  const handleActionsMenuItemClick = useCallback(
    (callback: () => void) => () => {
      setIsActionsMenuOpened(false);
      callback();
    },
    []
  );

  const generateListenersTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.LISTENER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        listenerId: id
      }),
    [
      matchParams.lbId,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const generatePoolsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.POOL, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId,
        poolId: id
      }),
    [
      matchParams.lbId,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "LoadBalancers",
      url: generatePath(ROUTES.LOAD_BALANCERS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: loadBalancerDetails?.name || "",
      url: generatePath(ROUTES.LOAD_BALANCER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        lbId: matchParams.lbId
      })
    }
  ];

  const handleEditLoadBalancerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_LOAD_BALANCER,
      isOpened: true
    });
  }, []);

  const handleDeleteLoadBalancerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_LOAD_BALANCER,
      isOpened: true
    });
  }, []);

  const handleCreateListenerButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_LISTENER,
      isOpened: true
    });
  }, []);

  const handleCreatePoolButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_POOL,
      isOpened: true
    });
  }, []);

  const handleCreatePoolInner = useCallback(() => {
    setIsCreatePoolInnerDialogOpened(true);
  }, []);

  const handleEditListenerMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_LISTENER,
      isOpened: true
    });
  }, []);

  const handleUnassignPoolFromListenerMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.UNASSIGN_POOL,
        isOpened: true
      });
    },
    []
  );

  const handleDeleteListenerMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_LISTENER,
      isOpened: true
    });
  }, []);

  const handleEditPoolMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_POOL,
      isOpened: true
    });
  }, []);

  const handleDeletePoolMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_POOL,
      isOpened: true
    });
  }, []);

  const handleAssociateFloatingIPMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ASSOCIATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleDisassociateFloatingIPMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DISASSOCIATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const actions = [
    loadBalancerDetails?.floating_ip
      ? {
          label: "Dissociate Floating IP",
          handler: handleDisassociateFloatingIPMenuItemClick
        }
      : {
          label: "Associate Floating IP",
          handler: handleAssociateFloatingIPMenuItemClick
        }
  ];

  const tableListenersActions: TableRowActionsMenuItem<TableListener>[] = [
    {
      label: "Edit",
      handler: handleEditListenerMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    },
    {
      label: "Unassign pool",
      handler: handleUnassignPoolFromListenerMenuItemClick,
      isDisabled: (listener) => !listener.default_pool_id
    },
    {
      label: "Delete",
      handler: handleDeleteListenerMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    }
  ];

  const tablePoolsActions: TableRowActionsMenuItem<TablePool>[] = [
    {
      label: "Edit",
      handler: handleEditPoolMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    },
    {
      label: "Delete",
      handler: handleDeletePoolMenuItemClick
      // isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    }
  ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      networksActions.getNetworks.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      networksActions.getSubnets.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.floating_ips}`,
        action: networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.loadBalancer}`,
        action: loadBalancersActions.getLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.pools}`,
        action: loadBalancersActions.listPools.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.listeners}`,
        action: loadBalancersActions.listListeners.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
    dispatch(loadBalancersActions.clear());
  });

  useEffect(() => {
    if (
      previousIsLoadBalancersOperationInProgress &&
      !isLoadBalancersOperationInProgress
    ) {
      if (!loadBalancerDetails) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          loadBalancersActions.getLoadBalancer.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!
          })
        );
        dispatch(
          networksActions.getFloatingIPs.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!
          })
        );
      }
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsLoadBalancersOperationInProgress,
    isLoadBalancersOperationInProgress,
    matchParams.lbId,
    loadBalancerDetails,
    history
  ]);

  useEffect(() => {
    if (previousIsPoolsOperationInProgress && !isPoolsOperationInProgress) {
      loadBalancersActions.listPools.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        lbId: matchParams.lbId!
      });
    }
  }, [
    previousIsPoolsOperationInProgress,
    isPoolsOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.lbId
  ]);

  useEffect(() => {
    if (
      previousIsListenersOperationInProgress &&
      !isListenersOperationInProgress
    ) {
      dispatch(
        loadBalancersActions.listListeners.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.lbId,
    previousIsListenersOperationInProgress,
    isListenersOperationInProgress
  ]);

  useEffect(() => {
    switch (activeTabIndex) {
      case TABS.LISTENERS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.LISTENERS]
          })
        });
        break;
      case TABS.POOLS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.POOLS]
          })
        });
        break;
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.lbId
  ]);

  const handleConfirmEditLoadBalancer = useCallback(
    (data: {
      name: string;
      description: string;
      lb_admin_state_up: boolean;
    }) => {
      dispatch(
        loadBalancersActions.updateLoadBalancer.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          data: {
            name: data.name,
            description: data.description.trim(),
            admin_state_up: data.lb_admin_state_up
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.lbId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteLoadBalancer = useCallback(() => {
    dispatch(
      loadBalancersActions.deleteLoadBalancer.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        lbId: matchParams.lbId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.lbId,
    handleCloseDialog
  ]);

  const handleConfirmCreateListener = useCallback(
    (data: {
      name: string;
      protocol: string;
      port: number;
      connection_limit: number;
      client_data_timeout?: number;
      member_connection_timeout?: number;
      member_data_timeout?: number;
      x_forwarded_for?: boolean;
      x_forwarded_proto?: boolean;
      x_forwarded_port?: boolean;
      default_pool?: SelectOption;
    }) => {
      const rawHeaders = {
        "X-Forwarded-For": data.x_forwarded_for,
        "X-Forwarded-Proto": data.x_forwarded_proto,
        "X-Forwarded-Port": data.x_forwarded_port
      };

      const insertHeaders = Object.entries(rawHeaders).reduce(
        (acc, [key, value]) => {
          if (value !== undefined) {
            acc[key] = value ? "True" : "False";
          }
          return acc;
        },
        {} as Record<string, string>
      );

      dispatch(
        loadBalancersActions.createListener.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          data: {
            name: data.name,
            protocol: data.protocol,
            protocol_port: data.port,
            connection_limit: data.connection_limit,
            timeout_client_data: data.client_data_timeout,
            timeout_member_connect: data.member_connection_timeout,
            timeout_member_data: data.member_data_timeout,
            default_pool_id: data.default_pool?.value,
            admin_state_up: true,
            insert_headers:
              Object.keys(insertHeaders).length > 0 ? insertHeaders : null
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.lbId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmEditListener = useCallback(
    (data: {
      name: string;
      description: string;
      connection_limit: number;
      client_data_timeout?: number;
      member_connection_timeout?: number;
      member_data_timeout?: number;
      x_forwarded_for?: boolean;
      x_forwarded_proto?: boolean;
      x_forwarded_port?: boolean;
      default_pool?: SelectOption;
      listener_admin_state_up: boolean;
    }) => {
      if (selectedItemId) {
        const rawHeaders = {
          "X-Forwarded-For": data.x_forwarded_for,
          "X-Forwarded-Proto": data.x_forwarded_proto,
          "X-Forwarded-Port": data.x_forwarded_port
        };

        const insertHeaders = Object.entries(rawHeaders).reduce(
          (acc, [key, value]) => {
            if (value !== undefined) {
              acc[key] = value ? "True" : "False";
            }
            return acc;
          },
          {} as Record<string, string>
        );

        dispatch(
          loadBalancersActions.updateListener.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            listenerId: selectedItemId,
            data: {
              name: data.name,
              description: data.description.trim(),
              admin_state_up: data.listener_admin_state_up,
              connection_limit: data.connection_limit,
              timeout_client_data: data.client_data_timeout,
              timeout_member_connect: data.member_connection_timeout,
              timeout_member_data: data.member_data_timeout,
              default_pool_id: data.default_pool?.value,
              insert_headers:
                Object.keys(insertHeaders).length > 0
                  ? insertHeaders
                  : undefined
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.lbId
    ]
  );

  const handleConfirmDeleteListener = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        loadBalancersActions.deleteListener.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          listenerId: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    selectedItemId,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.lbId,
    handleCloseDialog
  ]);

  const handleConfirmUnassignPool = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        loadBalancersActions.updateListener.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          listenerId: selectedItemId,
          data: {
            default_pool_id: ""
          }
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    handleCloseDialog,
    matchParams.lbId,
    selectedItemId,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleConfirmCreatePool = useCallback(
    (data: {
      name: string;
      pool_protocol?: string;
      algorithm: string;
      listener_id?: SelectOption;
      session_persistence?: SelectOption;
      cookie_name?: string;
    }) => {
      dispatch(
        loadBalancersActions.createPool.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          data: {
            name: data.name,
            protocol:
              data.pool_protocol ||
              tableListeners?.find(
                (l) => l.id === loadBalancerDetails?.listeners[0].id
              )?.protocol ||
              null,
            lb_method: data.algorithm,
            listener_id: data.listener_id ? data.listener_id.value : undefined,
            persistence: data.session_persistence
              ? {
                  type:
                    data.session_persistence?.value === "None"
                      ? ""
                      : data.session_persistence?.value,
                  cookie_name: data.cookie_name
                }
              : null,
            admin_state_up: true
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      loadBalancerDetails?.listeners,
      matchParams.lbId,
      matchParams.projectId,
      matchParams.regionId,
      tableListeners
    ]
  );

  const handleConfirmCreatePoolInner = useCallback(
    (data: {
      name: string;
      pool_protocol?: SelectOption;
      algorithm: string;
      session_persistence?: SelectOption;
      cookie_name?: string;
    }) => {
      dispatch(
        loadBalancersActions.createPool.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          data: {
            name: data.name,
            protocol:
              data.pool_protocol?.value ||
              tableListeners?.find(
                (l) => l.id === loadBalancerDetails?.listeners[0].id
              )?.protocol ||
              null,
            lb_method: data.algorithm,
            persistence: data.session_persistence
              ? {
                  type:
                    data.session_persistence?.value === "None"
                      ? ""
                      : data.session_persistence?.value,
                  cookie_name: data.cookie_name
                }
              : null,
            admin_state_up: true
          }
        })
      );
      handleCloseCreatePoolInnerDialog();
    },
    [
      dispatch,
      handleCloseCreatePoolInnerDialog,
      loadBalancerDetails?.listeners,
      matchParams.lbId,
      matchParams.projectId,
      matchParams.regionId,
      tableListeners
    ]
  );

  const handleConfirmEditPool = useCallback(
    (data: {
      name: string;
      description: string;
      algorithm: string;
      session_persistence?: SelectOption;
      cookie_name?: string;
      pool_admin_state_up: boolean;
    }) => {
      if (selectedItemId) {
        dispatch(
          loadBalancersActions.updatePool.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            lbId: matchParams.lbId!,
            poolId: selectedItemId,
            data: {
              name: data.name,
              description: data.description.trim(),
              lb_method: data.algorithm,
              persistence: data.session_persistence
                ? {
                    type:
                      data.session_persistence?.value === "None"
                        ? ""
                        : data.session_persistence?.value,
                    cookie_name: data.cookie_name
                  }
                : null,
              admin_state_up: data.pool_admin_state_up
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.lbId
    ]
  );

  const handleConfirmDeletePool = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        loadBalancersActions.deletePool.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          lbId: matchParams.lbId!,
          poolId: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    selectedItemId,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.lbId,
    handleCloseDialog
  ]);

  const handleConfirmAssociateFloatingIP = useCallback(
    (data: { interface: SelectOption; floatingIP: SelectOption }) => {
      dispatch(
        networksActions.updateFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: data.floatingIP.value,
          data: {
            ...data,
            port_id: loadBalancerDetails?.vip_port_id
          }
        })
      );
      handleCloseDialog();
    },
    [
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      loadBalancerDetails
    ]
  );

  const handleConfirmDisassociateFloatingIP = useCallback(() => {
    if (floatingIPs && loadBalancerDetails) {
      const loadBalancerFloatingIp = loadBalancerDetails?.floating_ip;

      const floatingIpId = floatingIPs?.find(
        (fip) => fip.floating_ip_address === loadBalancerFloatingIp
      )?.id;
      dispatch(
        networksActions.updateFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: floatingIpId || "",
          data: {
            port_id: ""
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    floatingIPs,
    loadBalancerDetails,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingListenerName = tableListeners?.find(
    (listener) => listener.id === deletingItemId
  )?.name;
  const deletingPoolName = tablePools?.find(
    (pool) => pool.id === deletingItemId
  )?.name;

  const selectedListener = tableListeners?.find((l) => l.id === deletingItemId);
  const selectedListenerProtocol = selectedListener?.protocol;

  const availablePoolProtocolOptions = filterAvailablePoolProtocols(
    selectedListenerProtocol
  ).map((protocol) => ({
    label: protocol.label,
    value: protocol.value
  }));

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_LISTENER]: {
      onConfirm: handleConfirmCreateListener,
      title: "Create Listener",
      isLocked: isPoolCreating,
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "protocol",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Protocol",
          helperText: "Choose Listener Protocol",
          options: Object.keys(LB_PROTOCOLS_LABELS).map((x) => ({
            label: LB_PROTOCOLS_LABELS[x],
            value: x
          })),
          defaultValue: LB_PROTOCOLS.TCP,
          rules: string()
        },
        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .required()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`),
          label: "Port"
        },
        {
          name: "connection_limit",
          type: FIELD_TYPES.NUMBER,
          defaultValue: -1,
          helperText:
            "Number of connections permitted for listener. Set -1 for unlimited connections.",
          min: -1,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              -1,
              "The connection limit must be a number greater than or equal to -1."
            ),
          label: "Connection Limit"
        },
        {
          name: "client_data_timeout",
          type: FIELD_TYPES.NUMBER,
          fullWidth: false,
          suffix: "ms",
          width: "33%",
          margin: "0.5%",
          defaultValue: 50000,
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol || fieldValues.protocol === LB_PROTOCOLS.UDP
            );
          },
          label: "Client Data Timeout"
        },
        {
          name: "member_connection_timeout",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 5000,
          fullWidth: false,
          width: "33%",
          margin: "0.5%",
          suffix: "ms",
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol || fieldValues.protocol === LB_PROTOCOLS.UDP
            );
          },
          label: "Member Connect Timeout"
        },
        {
          name: "member_data_timeout",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 50000,
          fullWidth: false,
          width: "33%",
          margin: "0px",
          suffix: "ms",
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol || fieldValues.protocol === LB_PROTOCOLS.UDP
            );
          },
          label: "Member Data Timeout"
        },
        {
          name: "headerInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: `\n\nInsert Headers:`
        },
        {
          name: "x_forwarded_for",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: "X-Forwarded-For"
        },
        {
          name: "x_forwarded_proto",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: "X-Forwarded-Proto"
        },
        {
          name: "x_forwarded_port",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue: false,
          isHidden: (fieldValues) => {
            return (
              !fieldValues.protocol ||
              fieldValues.protocol !== LB_PROTOCOLS.HTTP
            );
          },
          label: "X-Forwarded-Port"
        },
        {
          name: "label0",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "default_pool",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Default Pool",
          helperText:
            "Only unassigned pools with a compatible protocol can be selected.",
          onCreate: handleCreatePoolInner,
          createText: "Create new pool",
          options: (fieldValues) => {
            const listenerProtocol =
              typeof fieldValues.protocol === "string"
                ? fieldValues.protocol
                : undefined;

            const filteredPools = filterPoolsByListenerProtocol(
              pools?.filter((pool) => pool.listeners?.length === 0) || [],
              listenerProtocol
            );

            return filteredPools.map((pool) =>
              getSelectOption(pool, "name", "id")
            );
          },
          // options: pools
          //   ?.filter((pool) => pool.listeners?.length === 0)
          //   .map((pool) => getSelectOption(pool, "name", "id")),
          rules: selectOptionSchemaNotRequired.notRequired()
        }
      ]
    },
    [DIALOG_TYPES.CREATE_POOL]: {
      onConfirm: handleConfirmCreatePool,
      title: "Create Pool",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "divider2",
          type: FIELD_TYPES.DIVIDER,
          label: "Pool Protocol"
        },
        {
          name: "pool_protocol",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Protocol",
          helperText: "Choose a compatible with listener pool protocol.",
          options: Object.keys(POOL_PROTOCOLS_LABELS).map((x) => ({
            label: POOL_PROTOCOLS_LABELS[x],
            value: x
          })),
          defaultValue: POOL_PROTOCOLS.TCP,
          rules: string()
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Pool Algorithm"
        },
        {
          name: "algorithm",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Algorithm",
          helperText: `Select traffic distribution algorithm:\n\nLEAST CONNECTIONS - Sends traffic to the least loaded instance.\nROUND ROBIN - Balances requests evenly across instances.\nSOURCE IP - Routes the same IP to the same instance.`,
          options: Object.keys(LB_POOL_ALGORITHM_LABELS).map((x) => ({
            label: LB_POOL_ALGORITHM_LABELS[x],
            value: x
          })),
          defaultValue: LB_POOL_ALGORITHM.LEAST_CONNECTIONS,
          rules: string()
        },
        {
          name: "listener_id",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Default Listener",
          helperText:
            "Only unassigned listeners with a compatible protocol can be selected.",
          // onCreate: handleCreatePoolInner,
          // createText: "Create new pool",
          options: (fieldValues) => {
            const poolProtocol =
              typeof fieldValues.pool_protocol === "string"
                ? fieldValues.pool_protocol
                : undefined;

            const filteredListeners = filterListenersByPoolProtocol(
              listeners?.filter((listener) => !listener.default_pool_id) || [],
              poolProtocol
            );

            return filteredListeners.map((listener) =>
              getSelectOption(listener, "name", "id")
            );
          },
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "session_persistence",
          type: FIELD_TYPES.SELECT,
          label: "Session Persistence",
          variant: "outlined",
          helperText:
            "Type of session persistence for routing traffic to pool members.",
          options: Object.keys(LB_POOL_SESSION_PERSISTENCE_LABELS).map((x) => ({
            label: LB_POOL_SESSION_PERSISTENCE_LABELS[x],
            value: x
          })),
          defaultValue: {
            label: LB_POOL_SESSION_PERSISTENCE_LABELS.None,
            value: LB_POOL_SESSION_PERSISTENCE.None
          },
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "cookie_name",
          type: FIELD_TYPES.TEXT,
          label: "Cookie Name",
          isHidden: (fieldValues) => {
            const persistence =
              fieldValues.session_persistence as SelectOption | null;

            return (
              !persistence ||
              persistence.value !==
                String(LB_POOL_SESSION_PERSISTENCE.APP_COOKIE)
            );
          },
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.EDIT_LOAD_BALANCER]: {
      onConfirm: handleConfirmEditLoadBalancer,
      title: "Edit Load Balancer",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: loadBalancerDetails?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: loadBalancerDetails?.description || "",
          rules: string()
        },
        {
          name: "lb_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: loadBalancerDetails?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_LISTENER]: {
      onConfirm: handleConfirmEditListener,
      title: "Edit Listener",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)?.description ||
            "",
          rules: string()
        },
        {
          name: "connection_limit",
          type: FIELD_TYPES.NUMBER,
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.connection_limit || -1,
          helperText:
            "Number of connections permitted for listener. Set -1 for unlimited connections.",
          min: -1,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              -1,
              "The connection limit must be a number greater than or equal to -1."
            ),
          label: "Connection Limit"
        },
        {
          name: "client_data_timeout",
          type: FIELD_TYPES.NUMBER,
          fullWidth: false,
          suffix: "ms",
          width: "33%",
          margin: "0.5%",
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.timeout_client_data || 50000,
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: () => {
            return (
              tableListeners?.find((l) => l.id === selectedItemId)?.protocol ===
              LB_PROTOCOLS.UDP
            );
          },
          label: "Client Data Timeout"
        },
        {
          name: "member_connection_timeout",
          type: FIELD_TYPES.NUMBER,
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.timeout_member_connect || 5000,
          fullWidth: false,
          width: "33%",
          margin: "0.5%",
          suffix: "ms",
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return (
              tableListeners?.find((l) => l.id === selectedItemId)?.protocol ===
              LB_PROTOCOLS.UDP
            );
          },
          label: "Member Connect Timeout"
        },
        {
          name: "member_data_timeout",
          type: FIELD_TYPES.NUMBER,
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.timeout_member_data || 50000,
          fullWidth: false,
          width: "33%",
          margin: "0px",
          suffix: "ms",
          min: 0,
          max: MAX_LB_TIMEOUT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(0, ERROR_MESSAGES.LB_TIMEOUT)
            .max(MAX_LB_TIMEOUT, ERROR_MESSAGES.LB_TIMEOUT),
          isHidden: (fieldValues) => {
            return (
              tableListeners?.find((l) => l.id === selectedItemId)?.protocol ===
              LB_PROTOCOLS.UDP
            );
          },
          label: "Member Data Timeout"
        },
        {
          name: "headerInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: () => {
            return selectedListener?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: `\n\nInsert Headers:`
        },
        {
          name: "x_forwarded_for",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.x_forwarded_for || false,
          isHidden: () => {
            return selectedListener?.protocol !== LB_PROTOCOLS.HTTP;
          },
          // isHidden: () => {
          //   return (
          //     tableListeners?.find((l) => l.id === selectedItemId)?.protocol !==
          //     LB_PROTOCOLS.HTTP
          //   );
          // },
          label: "X-Forwarded-For"
        },
        {
          name: "x_forwarded_proto",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.x_forwarded_proto || false,
          isHidden: () => {
            return selectedListener?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: "X-Forwarded-Proto"
        },
        {
          name: "x_forwarded_port",
          type: FIELD_TYPES.CHECKBOX,
          defaultValue:
            tableListeners?.find((l) => l.id === selectedItemId)
              ?.x_forwarded_port || false,
          isHidden: () => {
            return selectedListener?.protocol !== LB_PROTOCOLS.HTTP;
          },
          label: "X-Forwarded-Port"
        },
        {
          name: "label",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "default_pool",
          type: FIELD_TYPES.SELECT,
          variant: "outlined",
          label: "Default Pool",
          helperText:
            "Only unassigned pools with a compatible protocol can be selected.",
          onCreate: handleCreatePoolInner,
          createText: "Create new pool",
          options: (() => {
            const currentPoolId = selectedListener?.default_pool_id;
            const listenerProtocol = selectedListener?.protocol;
            const compatiblePools = filterPoolsByListenerProtocol(
              pools || [],
              listenerProtocol
            );

            return compatiblePools
              ?.filter(
                (pool) =>
                  pool.listeners?.length === 0 || pool.id === currentPoolId
              )
              .map((pool) => ({
                label:
                  pool.id === currentPoolId
                    ? `${pool.name || pool.id} (current)`
                    : pool.name || pool.id,
                value: pool.id
              }));
          })(),

          // options: pools
          //   ?.filter((pool) => pool.listeners?.length === 0)
          //   .map((pool) => getSelectOption(pool, "id", "id")),
          defaultValue: (() => {
            const poolId = selectedListener?.default_pool_id ?? undefined;
            if (!poolId) return undefined;
            const currentPool = pools?.find((pool) => pool.id === poolId);
            return currentPool
              ? {
                  label: `${currentPool.name || poolId} (current)`,
                  value: poolId
                }
              : undefined;
          })(),
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "label0",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "listener_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tableListeners?.find((l) => l.id === selectedItemId)
            ?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.EDIT_POOL]: {
      onConfirm: handleConfirmEditPool,
      title: "Edit Pool",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            tablePools?.find((p) => p.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            tablePools?.find((p) => p.id === selectedItemId)?.description || "",
          rules: string()
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Pool Algorithm"
        },
        {
          name: "algorithm",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Algorithm",
          helperText: `Select traffic distribution algorithm:\n\nLEAST CONNECTIONS - Sends traffic to the least loaded instance.\nROUND ROBIN - Balances requests evenly across instances.\nSOURCE IP - Routes the same IP to the same instance.`,
          options: Object.keys(LB_POOL_ALGORITHM_LABELS).map((x) => ({
            label: LB_POOL_ALGORITHM_LABELS[x],
            value: x
          })),
          defaultValue:
            tablePools?.find((l) => l.id === selectedItemId)?.lb_algorithm ||
            LB_POOL_ALGORITHM.LEAST_CONNECTIONS,
          rules: string()
        },
        {
          name: "session_persistence",
          type: FIELD_TYPES.SELECT,
          label: "Session Persistence",
          variant: "outlined",
          helperText:
            "Type of session persistence for routing traffic to pool members.",
          options: Object.keys(LB_POOL_SESSION_PERSISTENCE_LABELS).map((x) => ({
            label: LB_POOL_SESSION_PERSISTENCE_LABELS[x],
            value: x
          })),
          defaultValue: (() => {
            const selectedPool = tablePools?.find(
              (l) => l.id === selectedItemId
            );
            const persistenceType = selectedPool?.session_persistence_type;

            return persistenceType
              ? { label: persistenceType, value: persistenceType }
              : {
                  label: LB_POOL_SESSION_PERSISTENCE_LABELS.None,
                  value: LB_POOL_SESSION_PERSISTENCE.None
                };
          })(),
          // defaultValue: {
          //   label: LB_POOL_SESSION_PERSISTENCE_LABELS.SOURCE_IP,
          //   value: LB_POOL_SESSION_PERSISTENCE.SOURCE_IP
          // },
          rules: selectOptionSchemaNotRequired.notRequired()
        },
        {
          name: "cookie_name",
          type: FIELD_TYPES.TEXT,
          label: "Cookie Name",
          defaultValue:
            tablePools?.find((l) => l.id === selectedItemId)
              ?.session_persistence_cookie_name || "",
          isHidden: (fieldValues) => {
            const persistence =
              fieldValues.session_persistence as SelectOption | null;

            return (
              !persistence ||
              persistence.value !==
                String(LB_POOL_SESSION_PERSISTENCE.APP_COOKIE)
            );
          },
          rules: string()
        },
        {
          name: "label",
          type: FIELD_TYPES.LABEL,
          label: ""
        },
        {
          name: "pool_admin_state_up",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: tablePools?.find((l) => l.id === selectedItemId)
            ?.admin_state_up,
          label: "Admin State"
        }
      ]
    },
    [DIALOG_TYPES.DELETE_LOAD_BALANCER]: {
      onConfirm: handleConfirmDeleteLoadBalancer,
      title: `Are you sure you want to delete current load balancer?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_LISTENER]: {
      onConfirm: handleConfirmDeleteListener,
      title: `Are you sure you want to delete "${deletingListenerName ?? "selected"}" listener?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.UNASSIGN_POOL]: {
      onConfirm: handleConfirmUnassignPool,
      title: `Are you sure you want to unassign pool from t"${deletingListenerName ?? "selected"}" listener?`,
      confirmButtonLabel: "Unassign"
    },
    [DIALOG_TYPES.DELETE_POOL]: {
      onConfirm: handleConfirmDeletePool,
      title: `Are you sure you want to delete "${deletingPoolName ?? "selected"}" pool?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.ASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmAssociateFloatingIP,
      title: "Associate floating IP",
      confirmButtonLabel: "Associate",
      fields: [
        {
          name: "floatingIP",
          type: FIELD_TYPES.SELECT,
          label: "Floating IP",
          options: () =>
            floatingIPs
              ?.filter((fip) => fip.status != "ACTIVE")
              .map((fip) => getSelectOption(fip, "floating_ip_address", "id")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DISASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmDisassociateFloatingIP,
      title: "Are you sure you want to dissociate floating IP with this LB?",
      confirmButtonLabel: "Dissociate"
    }
  };

  const tabContent = [
    <Table
      key={"listenersTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={tableListeners || []}
      columns={tableListenersColumns}
      actions={tableListenersActions}
      itemLink={{
        column: "name",
        getURL: generateListenersTableItemURL
      }}
      isLoading={!tableListeners}
      toolbarItems={
        <Button onClick={handleCreateListenerButtonClick} variant={"contained"}>
          Create Listener
        </Button>
      }
    />,
    <Table
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"poolTable"}
      rows={tablePools || []}
      columns={tablePoolsColumns}
      actions={tablePoolsActions}
      itemLink={{
        column: "name",
        getURL: generatePoolsTableItemURL
      }}
      isLoading={!tablePools}
      toolbarItems={
        <Button onClick={handleCreatePoolButtonClick} variant={"contained"}>
          Create Pool
        </Button>
      }
    />
  ];

  return (
    <>
      <Head title={title} />
      {loadBalancerDetails ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {loadBalancerDetails.id}
                </s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>IP: </s.DetailsTitle>
                <s.Tag label={loadBalancerDetails.vip_address} />

                <s.DetailsTitle>Floating IP: </s.DetailsTitle>
                {loadBalancerDetails.floating_ip ? (
                  <s.Tag label={loadBalancerDetails.floating_ip} />
                ) : (
                  <s.Tag label={"None"} />
                )}
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Provisioning Status:</s.DetailsTitle>
                <s.Tag label={loadBalancerDetails.provisioning_status} />
                <s.DetailsTitle>Admin State:</s.DetailsTitle>
                <s.Tag
                  label={loadBalancerDetails.admin_state_up ? "up" : "down"}
                />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Subnet ID: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {loadBalancerDetails.vip_subnet_id}
                </s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Subnet Name: </s.DetailsTitle>
                <s.DetailsInfo>{loadBalancerDetails.subnet_name}</s.DetailsInfo>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Network ID: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {loadBalancerDetails.vip_network_id}
                </s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Network Name: </s.DetailsTitle>
                <s.DetailsInfo>
                  {loadBalancerDetails.network_name}
                </s.DetailsInfo>
              </s.SummaryRow>
              {loadBalancerDetails.description && (
                <s.SummaryRow>
                  <s.DetailsTitle>Description: </s.DetailsTitle>
                  <s.DetailsInfo>
                    {loadBalancerDetails.description}
                  </s.DetailsInfo>
                </s.SummaryRow>
              )}
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditLoadBalancerButtonClick}
                    color={"inherit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteLoadBalancerButtonClick}
                    color={"inherit"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {actions.length > 0 && (
                <Tooltip title={"More actions"} placement={"top"} arrow>
                  <span>
                    <IconButton
                      onClick={handleActionsMenuButtonClick}
                      color={"inherit"}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Menu
                isOpened={isActionsMenuOpened}
                onClose={handleActionsMenuClose}
                anchorEl={actionsMenuButtonRef.current}
                items={actions.map((action) => ({
                  label: action.label,
                  onClick: handleActionsMenuItemClick(action.handler)
                }))}
              />
            </s.ActionsContainer>
          </s.SummaryContainer>
          <Tabs value={activeTabIndex} onChange={handleChangeTab}>
            {Object.values(TAB_TITLES).map((tabTitle) => (
              <Tab key={tabTitle} label={tabTitle} />
            ))}
          </Tabs>
          {tabContent[activeTabIndex]}
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
          <FormDialog
            isOpened={isCreatePoolInnerDialogOpened}
            onCancel={handleCloseCreatePoolInnerDialog}
            fields={[
              {
                name: "name",
                type: FIELD_TYPES.TEXT,
                label: "Name",
                rules: string()
                  .required()
                  .test({
                    name: "validateName",
                    test: validateName(ENTITY_NAME_LENGTH)
                  })
              },
              {
                name: "pool_protocol",
                type: FIELD_TYPES.SELECT,
                variant: "outlined",
                label: "Pool Protocol",
                helperText: "Choose a compatible with listener pool protocol.",
                options: availablePoolProtocolOptions,
                defaultValue: selectedListenerProtocol
                  ? {
                      label: selectedListenerProtocol,
                      value: selectedListenerProtocol
                    }
                  : undefined,
                rules: selectOptionSchema
              },
              {
                name: "divider",
                type: FIELD_TYPES.DIVIDER,
                label: "Pool Algorithm"
              },
              {
                name: "algorithm",
                type: FIELD_TYPES.TOGGLE_BUTTON,
                label: "Algorithm",
                helperText: `Select traffic distribution algorithm:\n\nLEAST CONNECTIONS - Sends traffic to the least loaded instance.\nROUND ROBIN - Balances requests evenly across instances.\nSOURCE IP - Routes the same IP to the same instance.`,
                options: Object.keys(LB_POOL_ALGORITHM_LABELS).map((x) => ({
                  label: LB_POOL_ALGORITHM_LABELS[x],
                  value: x
                })),
                defaultValue: LB_POOL_ALGORITHM.LEAST_CONNECTIONS,
                rules: string()
              },
              {
                name: "session_persistence",
                type: FIELD_TYPES.SELECT,
                label: "Session Persistence",
                variant: "outlined",
                helperText:
                  "Type of session persistence for routing traffic to pool members.",
                options: Object.keys(LB_POOL_SESSION_PERSISTENCE_LABELS).map(
                  (x) => ({
                    label: LB_POOL_SESSION_PERSISTENCE_LABELS[x],
                    value: x
                  })
                ),
                defaultValue: {
                  label: LB_POOL_SESSION_PERSISTENCE_LABELS.None,
                  value: LB_POOL_SESSION_PERSISTENCE.None
                },
                rules: selectOptionSchemaNotRequired.notRequired()
              },
              {
                name: "cookie_name",
                type: FIELD_TYPES.TEXT,
                label: "Cookie Name",
                isHidden: (fieldValues) => {
                  const persistence =
                    fieldValues.session_persistence as SelectOption | null;

                  return (
                    !persistence ||
                    persistence.value !==
                      String(LB_POOL_SESSION_PERSISTENCE.APP_COOKIE)
                  );
                },
                rules: string()
              }
            ]}
            onConfirm={handleConfirmCreatePoolInner}
            title={"Create Pool"}
            confirmButtonLabel={"Create"}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
