export enum DIALOG_TYPES {
  EDIT_LOAD_BALANCER,
  DELETE_LOAD_BALANCER,
  CREATE_LISTENER,
  EDIT_LISTENER,
  DELETE_LISTENER,
  CREATE_POOL,
  EDIT_POOL,
  DELETE_POOL,
  ASSOCIATE_FLOATING_IP,
  DISASSOCIATE_FLOATING_IP,
  UNASSIGN_POOL
}

export enum TABS {
  LISTENERS,
  POOLS
}
