export enum DIALOG_TYPES {
  CREATE_NETWORK,
  EDIT_NETWORK,
  DELETE_NETWORK,
  CREATE_ROUTER,
  EDIT_ROUTER,
  DELETE_ROUTER,
  CREATE_FLOATING_IP,
  DELETE_FLOATING_IP,
  EDIT_FLOATING_IP,
  DISASSOCIATE_FLOATING_IP,
  ASSOCIATE_FLOATING_IP
}

export enum TABS {
  NETWORKS,
  ROUTERS,
  FLOATING_IPS
}

export enum INTERFACE_TYPE {
  VM = "VIRTUAL MACHINE",
  LB = "LOAD BALANCER"
}

export const INTERFACE_TYPE_LABELS = {
  [INTERFACE_TYPE.VM]: "VIRTUAL MACHINE",
  [INTERFACE_TYPE.LB]: "LOAD BALANCER"
};
