import {
  LB_PROTOCOLS_LABELS,
  Listener,
  Pool,
  POOL_PROTOCOLS_LABELS
} from "modules/loadBalancers/types";

export const filterPoolsByListenerProtocol = (
  pools: Pool[] = [],
  listenerProtocol?: string
): Pool[] => {
  const protocolCompatibility: Record<string, string[]> = {
    HTTP: ["HTTP", "PROXY"],
    HTTPS: ["HTTPS", "PROXY", "TCP"],
    SCTP: ["SCTP"],
    TCP: ["HTTP", "HTTPS", "TCP", "PROXY"],
    TERMINATED_HTTPS: ["HTTP", "PROXY"],
    UDP: ["UDP"]
  };

  if (!listenerProtocol) return pools ?? [];

  const allowedPoolProtocols = protocolCompatibility[listenerProtocol] || [];

  return (
    pools.filter((pool) => allowedPoolProtocols.includes(pool.protocol)) ?? []
  );
};

export const filterAvailablePoolProtocols = (
  listenerProtocol?: string
): { label: string; value: string }[] => {
  const protocolCompatibility: Record<string, string[]> = {
    HTTP: ["HTTP", "PROXY"],
    HTTPS: ["HTTPS", "PROXY", "TCP"],
    SCTP: ["SCTP"],
    TCP: ["HTTP", "HTTPS", "TCP", "PROXY"],
    TERMINATED_HTTPS: ["HTTP", "PROXY"],
    UDP: ["UDP"]
  };
  if (!listenerProtocol) {
    return Object.keys(POOL_PROTOCOLS_LABELS).map((protocol) => ({
      label: POOL_PROTOCOLS_LABELS[protocol] || protocol,
      value: protocol
    }));
  }

  const allowedPoolProtocols = protocolCompatibility[listenerProtocol] || [];

  return allowedPoolProtocols.map((protocol) => ({
    label: POOL_PROTOCOLS_LABELS[protocol] || protocol,
    value: protocol
  }));
};

export const filterListenersByPoolProtocol = (
  listeners: Listener[] = [],
  poolProtocol?: string
): Listener[] => {
  const protocolCompatibility: Record<string, string[]> = {
    HTTP: ["HTTP", "TERMINATED_HTTPS", "TCP"],
    HTTPS: ["HTTPS", "TCP"],
    SCTP: ["SCTP"],
    TCP: ["TCP", "HTTPS"],
    PROXY: ["HTTP", "HTTPS", "TCP", "TERMINATED_HTTPS"],
    UDP: ["UDP"]
  };

  if (!poolProtocol) return listeners ?? [];

  const allowedListenerProtocols = protocolCompatibility[poolProtocol] || [];

  return (
    listeners.filter((listener) =>
      allowedListenerProtocols.includes(listener.protocol)
    ) ?? []
  );
};

export const filterAvailableListenerProtocols = (
  poolProtocol?: string
): { label: string; value: string }[] => {
  const protocolCompatibility: Record<string, string[]> = {
    HTTP: ["HTTP", "TERMINATED_HTTPS", "TCP"],
    HTTPS: ["HTTPS", "TCP"],
    SCTP: ["SCTP"],
    TCP: ["TCP", "HTTPS"],
    PROXY: ["HTTP", "HTTPS", "TCP", "TERMINATED_HTTPS"],
    UDP: ["UDP"]
  };
  if (!poolProtocol) {
    return Object.keys(LB_PROTOCOLS_LABELS).map((protocol) => ({
      label: LB_PROTOCOLS_LABELS[protocol] || protocol,
      value: protocol
    }));
  }

  const allowedListenerProtocols = protocolCompatibility[poolProtocol] || [];

  return allowedListenerProtocols.map((protocol) => ({
    label: LB_PROTOCOLS_LABELS[protocol] || protocol,
    value: protocol
  }));
};
