import { Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem,
  TABLE_SORTING_TYPES
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import * as loadBalancersActions from "modules/loadBalancers/actions";
import { tableVirtualMachinesSelector } from "modules/instances/selectors";
import * as networksActions from "modules/networks/actions";
import {
  interfacesSelector,
  isFloatingIPCreatingSelector,
  isFloatingIPDeletingSelector,
  isFloatingIPUpdatingSelector,
  isNetworkCreatingSelector,
  isNetworkDeletingSelector,
  isNetworkLoadingSelector,
  isNetworkUpdatingSelector,
  isRouterCreatingSelector,
  isRouterDeletingSelector,
  isRouterLoadingSelector,
  isRouterUpdatingSelector,
  tableFloatingIPsSelector,
  tableNetworksSelector,
  tableRoutersSelector
} from "modules/networks/selectors";
import {
  TableFloatingIP,
  TableNetwork,
  TableRouter
} from "modules/networks/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { validateName } from "utils/validateName";
import { string } from "yup";
import { ENTITY_NAME_LENGTH, ROUTES, SPECIAL_NAMES } from "../../constants";
import {
  DIALOG_TYPES,
  INTERFACE_TYPE,
  INTERFACE_TYPE_LABELS,
  TABS
} from "./types";
import { tableLoadBalancersSelector } from "modules/loadBalancers/selectors";
import { getSelectOption } from "utils/getSelectOption";

const POLL_ID_PREFIX = "NETWORKING";

const POLL_IDS = {
  networks: "NETWORKS",
  routers: "ROUTERS",
  floatingIPs: "FLOATING_IPS"
};

const title = "Networking";

const tableNetworksColumns: TableColumn<TableNetwork>[] = [
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "shared", label: "Shared" },
  {
    key: "updatedAt",
    label: "Updated",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const tableFloatingIPsColumns: TableColumn<TableFloatingIP>[] = [
  { key: "id", label: "ID" },
  { key: "ipAddress", label: "Floating IP" },
  { key: "fixedIPAddress", label: "Associated IP" },
  { key: "descriptions", label: "Description" },
  { key: "status", label: "Status" }
];

const tableRoutersColumns: TableColumn<TableRouter>[] = [
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "externalGateway", label: "External gateway" },
  { key: "description", label: "Description" }
];

export const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.NETWORKS]: "Networks",
  [TABS.ROUTERS]: "Routers",
  [TABS.FLOATING_IPS]: "Floating IPs"
};

export const Networking: FC = () => {
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.NETWORKS) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const isNetworkCreating = useSelector(isNetworkCreatingSelector);
  const isNetworkUpdating = useSelector(isNetworkUpdatingSelector);
  const isNetworkLoading = useSelector(isNetworkLoadingSelector);
  const isNetworkDeleting = useSelector(isNetworkDeletingSelector);
  const isRouterCreating = useSelector(isRouterCreatingSelector);
  const isRouterUpdating = useSelector(isRouterUpdatingSelector);
  const isRouterLoading = useSelector(isRouterLoadingSelector);
  const isRouterDeleting = useSelector(isRouterDeletingSelector);
  const isFloatingIPCreating = useSelector(isFloatingIPCreatingSelector);
  const isFloatingIPUpdating = useSelector(isFloatingIPUpdatingSelector);
  const isFloatingIPDeleting = useSelector(isFloatingIPDeletingSelector);
  const tableVirtualMachines = useSelector(tableVirtualMachinesSelector);
  const interfaces = useSelector(interfacesSelector);
  const tableLoadBalancers = useSelector(tableLoadBalancersSelector);
  const history = useNavigate();
  const isNetworkOperationInProgress =
    isNetworkCreating ||
    isNetworkUpdating ||
    isNetworkDeleting ||
    isNetworkLoading;

  const isRouterOperationInProgress =
    isRouterCreating || isRouterUpdating || isRouterDeleting || isRouterLoading;

  const isFloatingIPOperationInProgress =
    isFloatingIPCreating || isFloatingIPUpdating || isFloatingIPDeleting;

  const previousIsFloatingIPOperationInProgress = usePrevious(
    isFloatingIPOperationInProgress
  );

  const previousIsNetworkOperationInProgress = usePrevious(
    isNetworkOperationInProgress
  );
  const previousIsRouterOperationInProgress = usePrevious(
    isRouterOperationInProgress
  );
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const networks = useSelector(tableNetworksSelector);
  const floatingIPs = useSelector(tableFloatingIPsSelector);
  const routers = useSelector(tableRoutersSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_NETWORK, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isOperationInProgress =
    isNetworkCreating ||
    isNetworkUpdating ||
    isNetworkDeleting ||
    isFloatingIPCreating ||
    isFloatingIPUpdating ||
    isFloatingIPDeleting ||
    isRouterCreating ||
    isRouterUpdating ||
    isRouterDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const generateNetworksTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.NETWORK, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        networkId: id
      }),
    [matchParams.organizationId, matchParams.projectId, matchParams.regionId]
  );

  const generateRoutersTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.ROUTER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        routerId: id
      }),
    [matchParams.organizationId, matchParams.projectId, matchParams.regionId]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Networking",
      url: generatePath(ROUTES.NETWORKING, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const handleCreateNetworkButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_NETWORK,
      isOpened: true
    });
  }, []);

  const handleEditNetworkMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_NETWORK,
      isOpened: true
    });
  }, []);

  const handleDeleteNetworkMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_NETWORK,
      isOpened: true
    });
  }, []);

  const handleCreateFloatingIPButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleDeleteFloatingIPMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleEditFloatingIPMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleDisassociateFloatingIPMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.DISASSOCIATE_FLOATING_IP,
        isOpened: true
      });
    },
    []
  );

  const handleAssociateFloatingIPMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.ASSOCIATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleEditRouterMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_ROUTER,
      isOpened: true
    });
  }, []);

  const handleDeleteRouterMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_ROUTER,
      isOpened: true
    });
  }, []);

  const tableNetworkActions: TableRowActionsMenuItem<TableNetwork>[] = [
    {
      label: "Edit",
      handler: handleEditNetworkMenuItemClick,
      isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    },
    {
      label: "Delete",
      handler: handleDeleteNetworkMenuItemClick,
      isDisabled: (network) => network.name == SPECIAL_NAMES.NETWORK
    }
  ];

  const tableFloatingIPActions: TableRowActionsMenuItem<TableFloatingIP>[] = [
    {
      label: "Associate",
      handler: handleAssociateFloatingIPMenuItemClick
    },
    {
      label: "Disassociate",
      handler: handleDisassociateFloatingIPMenuItemClick,
      isDisabled: (floatingIP) => !floatingIP.fixedIPAddress
    },
    {
      label: "Edit",
      handler: handleEditFloatingIPMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteFloatingIPMenuItemClick
    }
  ];

  const handleCreateRouterButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_ROUTER,
      isOpened: true
    });
  }, []);

  const tableRouterActions: TableRowActionsMenuItem<TableRouter>[] = [
    {
      label: "Edit",
      handler: handleEditRouterMenuItemClick,
      isDisabled: (router) => router.name == SPECIAL_NAMES.NETWORK
    },
    {
      label: "Delete",
      handler: handleDeleteRouterMenuItemClick,
      isDisabled: (router) => router.name == SPECIAL_NAMES.NETWORK
    }
  ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      networksActions.getRouters.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      networksActions.getFloatingIPs.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      networksActions.getNetworks.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
    dispatch(
      networksActions.getInterfaces.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(networksActions.clear());
    dispatch(projectsActions.clear());
    dispatch(loadBalancersActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        networksActions.getNetworks.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (previousIsNetworkOperationInProgress && !isNetworkOperationInProgress) {
      dispatch(
        networksActions.getNetworks.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsNetworkOperationInProgress,
    isNetworkOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (previousIsRouterOperationInProgress && !isRouterOperationInProgress) {
      dispatch(
        networksActions.getRouters.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsRouterOperationInProgress,
    isRouterOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (
      previousIsFloatingIPOperationInProgress &&
      !isFloatingIPOperationInProgress
    ) {
      dispatch(
        networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsFloatingIPOperationInProgress,
    isFloatingIPOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.getInterfaces.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [selectedItemId, dispatch, matchParams.regionId, matchParams.projectId]);

  useEffect(() => {
    Object.values([
      POLL_IDS.networks,
      POLL_IDS.routers,
      POLL_IDS.floatingIPs
    ]).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    switch (activeTabIndex) {
      case TABS.NETWORKS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.NETWORKS]
          })
        });
        dispatch(networksActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.networks}`,
            action: networksActions.getNetworks.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.ROUTERS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.ROUTERS]
          })
        });
        dispatch(networksActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.routers}`,
            action: networksActions.getRouters.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.FLOATING_IPS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.FLOATING_IPS]
          })
        });
        dispatch(networksActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.floatingIPs}`,
            action: networksActions.getFloatingIPs.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        dispatch(
          networksActions.getInterfaces.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!
          })
        );
        dispatch(
          networksActions.getNetworks.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!
          })
        );
        dispatch(
          instancesActions.getInstances.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!
          })
        );
        dispatch(
          loadBalancersActions.listLoadBalancers.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!
          })
        );
        break;
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleConfirmCreateNetwork = useCallback(
    (data: { name: string; description: string }) => {
      dispatch(
        networksActions.createNetwork.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            description: data.description.trim()
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditNetwork = useCallback(
    (data: { name: string; description: string }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateNetwork.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteNetwork = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteNetwork.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmCreateRouter = useCallback(
    (data: {
      name: string;
      description: string;
      is_external_network_enabled: boolean;
    }) => {
      dispatch(
        networksActions.createRouter.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            description: data.description.trim()
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditRouter = useCallback(
    (data: { name: string; description: string }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateRouter.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteRouter = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteRouter.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmCreateFloatingIP = useCallback(
    (data: {
      description: string;
      associate_with_interface: boolean;
      type: string;
      interface: SelectOption;
    }) => {
      const publicNetworkID = networks?.find(
        (network) => network.name === "public"
      )?.id;
      if (publicNetworkID) {
        dispatch(
          networksActions.createFloatingIP.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            data: {
              ...data,
              description: data.description,
              floating_network_id: publicNetworkID,
              port_id: data.interface ? data.interface.value : ""
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      networks,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId
    ]
  );

  const handleConfirmDeleteFloatingIP = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmEditFloatingIP = useCallback(
    (data: { description: string }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateFloatingIP.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDisassociateFloatingIP = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.updateFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId,
          data: {
            port_id: ""
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmAssociateFloatingIP = useCallback(
    (data: { interface: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateFloatingIP.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              port_id: data.interface.value
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingNetworkName = networks?.find(
    (network) => network.id === deletingItemId
  )?.name;
  const deletingRouterName = routers?.find(
    (router) => router.id === deletingItemId
  )?.name;
  const deletingFloatingIP = floatingIPs?.find(
    (floatingIP) => floatingIP.id === deletingItemId
  )?.ipAddress;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_NETWORK]: {
      onConfirm: handleConfirmCreateNetwork,
      title: "Create network",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.EDIT_NETWORK]: {
      onConfirm: handleConfirmEditNetwork,
      title: "Edit network",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            networks?.find((network) => network.id === selectedItemId)?.name ||
            "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            networks?.find((network) => network.id === selectedItemId)
              ?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_NETWORK]: {
      onConfirm: handleConfirmDeleteNetwork,
      title: `Are you sure you want to delete "${
        deletingNetworkName ?? "selected"
      }" network?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_ROUTER]: {
      onConfirm: handleConfirmCreateRouter,
      title: "Create router",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        },
        {
          name: "is_external_network_enabled",
          type: FIELD_TYPES.CHECKBOX,
          label: "Enable external gateway",
          defaultValue: false
        }
      ]
    },
    [DIALOG_TYPES.EDIT_ROUTER]: {
      onConfirm: handleConfirmEditRouter,
      title: "Edit router",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            routers?.find((router) => router.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            routers?.find((router) => router.id === selectedItemId)
              ?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_ROUTER]: {
      onConfirm: handleConfirmDeleteRouter,
      title: `Are you sure you want to delete "${
        deletingRouterName ?? "selected"
      }" router?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_FLOATING_IP]: {
      onConfirm: handleConfirmCreateFloatingIP,
      title: "Create floating IP",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        },
        {
          name: "associate_with_interface",
          type: FIELD_TYPES.CHECKBOX,
          label: "associate with interface",
          defaultValue: false
        },
        {
          name: "type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Target Resource Type",
          helperText: "Select the resource to attach this floating IP to.",
          options: Object.keys(INTERFACE_TYPE_LABELS).map((x) => ({
            label: INTERFACE_TYPE_LABELS[x],
            value: x
          })),
          defaultValue: INTERFACE_TYPE.VM,
          dependent_fields: ["interface"],
          isHidden: (fieldValues) => !fieldValues.associate_with_interface,
          rules: string()
        },
        // {
        //   name: "interface",
        //   type: FIELD_TYPES.SELECT,
        //   label: "VM Interface",
        //   dependent_fields: ["interface"],
        //   isHidden: (fieldValues) =>
        //     !fieldValues.associate_with_interface &&
        //     fieldValues.type !== INTERFACE_TYPE.VM,
        //   options: () => {
        //     if (interfaces && tableVirtualMachines) {
        //       const opts: string[] = [];
        //       const selectOpts: SelectOption[] = [];
        //       tableVirtualMachines.forEach((vm) => {
        //         opts.push(...getValidInterfaceSelectOpts(vm.ip, vm.name));
        //       });
        //       opts.forEach((o) => {
        //         const attachedInterface = interfaces.find(
        //           (i) => i.fixed_ips[0].ip_address === o.split(":")[1].trim()
        //         );
        //         const value = attachedInterface?.id;
        //         if (value) {
        //           selectOpts.push({
        //             label: o,
        //             value: value
        //           });
        //         }
        //       });
        //       return selectOpts;
        //     }
        //   }
        // },
        {
          name: "interface",
          type: FIELD_TYPES.SELECT,
          label: (fieldValues) => {
            switch (fieldValues.type) {
              case INTERFACE_TYPE.VM:
                return "VM Interface";
              case INTERFACE_TYPE.LB:
                return "Load Balancer";
              default:
                return "Choose Target Resource";
            }
          },
          isHidden: (fieldValues) => !fieldValues.associate_with_interface,
          options: (fieldValues) => {
            const { type } = fieldValues;
            switch (type) {
              case INTERFACE_TYPE.VM:
                if (interfaces && tableVirtualMachines) {
                  const opts: string[] = [];
                  const selectOpts: SelectOption[] = [];

                  tableVirtualMachines.forEach((vm) => {
                    opts.push(...getValidInterfaceSelectOpts(vm.ip, vm.name));
                  });

                  opts.forEach((o) => {
                    const attachedInterface = interfaces.find(
                      (i) =>
                        i.fixed_ips[0].ip_address === o.split(":")[1].trim()
                    );
                    const value = attachedInterface?.id;
                    if (value) {
                      selectOpts.push({ label: o, value });
                    }
                  });

                  return selectOpts;
                }
                return [];

              case INTERFACE_TYPE.LB:
                return (
                  tableLoadBalancers
                    ?.filter((lb) => !lb.floating_ip)
                    .map((lb) =>
                      getSelectOption(lb, "vip_address", "vip_port_id")
                    ) || []
                );

              default:
                return [];
            }
          }
        }
      ]
    },
    [DIALOG_TYPES.DELETE_FLOATING_IP]: {
      onConfirm: handleConfirmDeleteFloatingIP,
      title: `Are you sure you want to delete "${
        deletingFloatingIP ?? "selected"
      }" floating IP?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.EDIT_FLOATING_IP]: {
      onConfirm: handleConfirmEditFloatingIP,
      title: "Edit floating IP",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            floatingIPs?.find((fip) => fip.id === selectedItemId)
              ?.descriptions || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DISASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmDisassociateFloatingIP,
      title: "Are you sure you want to disassociate selected floating IP?",
      confirmButtonLabel: "Disassociate"
    },
    [DIALOG_TYPES.ASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmAssociateFloatingIP,
      title: "Associate floating IP",
      confirmButtonLabel: "Associate",
      fields: [
        // {
        //   name: "divider",
        //   type: FIELD_TYPES.DIVIDER,
        //   label: "Target Resource Type"
        // },
        {
          name: "type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Target Resource Type",
          helperText: "Select target resource type",
          options: Object.keys(INTERFACE_TYPE_LABELS).map((x) => ({
            label: INTERFACE_TYPE_LABELS[x],
            value: x
          })),
          defaultValue: INTERFACE_TYPE.VM,
          dependent_fields: ["interface"],
          rules: string()
        },
        {
          name: "interface",
          type: FIELD_TYPES.SELECT,
          label: (fieldValues) => {
            switch (fieldValues.type) {
              case INTERFACE_TYPE.VM:
                return "VM Interface";
              case INTERFACE_TYPE.LB:
                return "Load Balancer";
              default:
                return "Choose Target Resource";
            }
          },
          defaultValue: () => {
            const fip = floatingIPs?.find((fip) => fip.id === selectedItemId);
            if (!fip) return null;

            const i = interfaces?.find(
              (i) => i.fixed_ips[0].ip_address === fip.fixedIPAddress
            );
            if (i) {
              return {
                label: fip.fixedIPAddress,
                value: i.id
              };
            }

            const lb = tableLoadBalancers?.find(
              (lb) => lb.vip_address === fip.fixedIPAddress
            );
            if (lb) {
              return {
                label: lb.vip_address,
                value: lb.vip_port_id
              };
            }

            return null;
          },

          options: (fieldValues) => {
            const { type } = fieldValues;
            switch (type) {
              case INTERFACE_TYPE.VM:
                if (interfaces && tableVirtualMachines) {
                  const opts: string[] = [];
                  const selectOpts: SelectOption[] = [];
                  tableVirtualMachines.forEach((vm) => {
                    opts.push(...getValidInterfaceSelectOpts(vm.ip, vm.name));
                  });
                  opts.forEach((o) => {
                    const attachedInterface = interfaces.find(
                      (i) =>
                        i.fixed_ips[0].ip_address === o.split(":")[1].trim()
                    );
                    const value = attachedInterface?.id;
                    if (value) {
                      selectOpts.push({
                        label: o,
                        value: value
                      });
                    }
                  });
                  return selectOpts;
                }
                return [];

              case INTERFACE_TYPE.LB:
                return (
                  tableLoadBalancers
                    ?.filter((lb) => !lb.floating_ip)
                    .map((lb) =>
                      getSelectOption(lb, "vip_address", "vip_port_id")
                    ) || []
                );

              default:
                return [];
            }
          },
          rules: selectOptionSchema
        }
        // {
        //   name: "interface",
        //   type: FIELD_TYPES.SELECT,
        //   label: "Interface",
        //   defaultValue: () => {
        //     const fip = floatingIPs?.find((fip) => fip.id === selectedItemId);
        //     const i = interfaces?.find(
        //       (i) => i.fixed_ips[0].ip_address === fip?.fixedIPAddress
        //     );
        //     if (fip && i) {
        //       return { label: fip.fixedIPAddress, value: i.id };
        //     } else {
        //       return null;
        //     }
        //   },
        //   options: () => {
        //     if (interfaces && tableVirtualMachines) {
        //       const opts: string[] = [];
        //       const selectOpts: SelectOption[] = [];
        //       tableVirtualMachines.forEach((vm) => {
        //         opts.push(...getValidInterfaceSelectOpts(vm.ip, vm.name));
        //       });
        //       opts.forEach((o) => {
        //         const attachedInterface = interfaces.find(
        //           (i) => i.fixed_ips[0].ip_address === o.split(":")[1].trim()
        //         );
        //         const value = attachedInterface?.id;
        //         if (value) {
        //           selectOpts.push({
        //             label: o,
        //             value: value
        //           });
        //         }
        //       });
        //       return selectOpts;
        //     }
        //   },
        //   rules: selectOptionSchema
        // }
      ]
    }
  };

  const getValidInterfaceSelectOpts = (str: string, name: string): string[] => {
    const ips = str.split(" ");
    const result: string[] = [];
    ips.forEach((ip) => {
      if (ip) {
        const parts = ip.split(":");
        if (parts[0] !== "public") {
          result.push(name + ":" + parts[1]);
        }
      }
    });
    return result;
  };

  const tabContent = [
    <Table
      key={"networksTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={networks || []}
      columns={tableNetworksColumns}
      actions={tableNetworkActions}
      itemLink={{
        column: "name",
        getURL: generateNetworksTableItemURL
      }}
      isLoading={!networks}
      toolbarItems={
        <Button onClick={handleCreateNetworkButtonClick} variant={"contained"}>
          Create network
        </Button>
      }
    />,
    <Table
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"routersTable"}
      rows={routers || []}
      columns={tableRoutersColumns}
      actions={tableRouterActions}
      itemLink={{
        column: "name",
        getURL: generateRoutersTableItemURL
      }}
      isLoading={!routers}
      toolbarItems={
        <Button onClick={handleCreateRouterButtonClick} variant={"contained"}>
          Create router
        </Button>
      }
    />,
    <Table
      key={"floatingIPsTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={floatingIPs || []}
      columns={tableFloatingIPsColumns}
      actions={tableFloatingIPActions}
      isLoading={!floatingIPs}
      toolbarItems={
        <Button
          onClick={handleCreateFloatingIPButtonClick}
          variant={"contained"}
        >
          Create Floating IP
        </Button>
      }
    />
  ];

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Tabs value={activeTabIndex} onChange={handleChangeTab}>
        {Object.values(TAB_TITLES).map((tabTitle) => (
          <Tab key={tabTitle} label={tabTitle} />
        ))}
      </Tabs>
      {tabContent[activeTabIndex]}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
