import Paper from "@mui/material/Paper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.spacing(45)};
  height: 100%;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const PostsContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(9)};
`;

export const NewsFeedPost = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const NewsFeedPostTitle = styled(Typography)<
  TypographyProps<"h3", { component: "h3" }>
>`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const NewsFeedPostContentImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
export const NewsFeedPostContentPreformattedText = styled.pre`
  white-space: pre-wrap;
  overflow-wrap: break-word; /* Replaces the deprecated 'word-break: break-word' */
  /* word-break: break-word; */
`;
